import styled, { keyframes, css } from 'styled-components';

export const Container = styled.div`
  padding: 10px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  h1 {
    margin-left: 15px;
  }

  @media (max-width: 768px) {
    width: 100%;
    display: flex;
    flex-direction: column;

    h1 {
    text-align: center;
   } 
  }
`;

export const Contato = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 15px;  
  margin-bottom: 10px;
`;
export const Telefone = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

`;
export const Email = styled.div`
  display: flex;
`;

// export const CardImage = styled.div`
//   background-image: url(${({ imageName }) => require(`../assets/images/${imageName}`)});
//   background-size: cover; 
//   background-position: center;
//   border-radius: 50%;
//   background-repeat: no-repeat;
//   width: ${({ size }) => size};
//   height: ${({ size }) => size};
// `;

// Define a animação shake
const shake = keyframes`
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
  100.1% { transform: translate(0, 0) rotate(0); } /* Pause depois do shake */
  100.2% { transform: translate(0, 0) rotate(0); }
`;

export const CardImage = styled.div`
  background-image: url(${({ imageName }) => require(`../assets/images/${imageName}`)});
  background-size: cover;
  background-position: center;
  border-radius: 50%;
  background-repeat: no-repeat;
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  
  /* Aplica a animação shake condicionalmente */
  ${({ shouldShake }) => shouldShake && css`
    animation: ${shake} 5s infinite;
    animation-timing-function: ease-in-out;
  `}
`;
