import { useEffect, useState } from 'react';
import PageHeader from '../../components/PageHeader';
import MinhaContaForm from '../../components/MinhaContaForm';

import MinhaContaService from '../../services/MinhaContaService';
import toast from '../../utils/toast';
import MenuHamburguer from '../../components/MenuHamburguer';

export default function MinhaConta() {
  // const [dadosUser, setDadosUser] = useState('');
  const [isLoadingDadosUser, setIsLoadingDadosUser] = useState(true);

  // useEffect(() => {
  //   async function buscaUser() {
  //     try {
  //       const userDados = await MinhaContaService.carregaDados();
        
  //       setDadosUser(userDados.dadosUser);
  //     } catch {} finally {
  //       setIsLoadingDadosUser(false);
  //     }
  //   }

  //   buscaUser();
  // }, []);

  async function handleSubmit(formData) {
    try {
      const user = await MinhaContaService.minhaConta(formData);
      toast({
        type: 'success',
        text: 'Sua conta foi atualizada com sucesso.',
      });

    } catch (error) {
      toast({
        type: 'danger',
        text: 'Ocorreu um erro ao tentar atualizar a sua conta.',
      });
    }
  }

  return (
    <>
      <MenuHamburguer />
      <PageHeader
        historyUrl="/chatbot/home"
        title="Minha Conta"
      />
      <p>Lembrete para campo senha: </p>
      <p>1. Caso não for alterar a senha insira a atual</p>
      <br></br>
      <MinhaContaForm
        buttonLabel="Atualizar"
        onSubmit={handleSubmit}
      />
    </>
  );
}
