import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import arrow from '../../../../assets/images/icons/arrow.svg';
import edit from '../../../../assets/images/icons/edit.svg';
import trash from '../../../../assets/images/icons/trash.svg';

import * as S from './styles';

export default function DialogueList({
  filteredDialogues,
  orderBy,
  onToggleOrderBy,
  onDeleteDialogue,
}) {
  return (
    <>
      {filteredDialogues.length > 0 && (
        <S.ListHeader orderBy={orderBy}>
          <button type="button" onClick={onToggleOrderBy}>
            <span>Pergunta</span>
            <img src={arrow} alt="Flecha" />
          </button>
        </S.ListHeader>
      )}

      {filteredDialogues.map((dialogue) => (
        <S.Card key={dialogue.id}>
          <div className="info">
            <div className="dialogue-input">
              <strong>{dialogue.input}</strong>
              {/* {dialogue.category_name && <small>{dialogue.category_name}</small>} */}
            </div>
            <span>{dialogue.output}</span>
          </div>

          <div className="actions">
            <Link to={`/chatbot/edit/dialogue/${dialogue.id}`}>
              <img src={edit} alt="Editar" />
            </Link>
            <button
              type="button"
              onClick={() => onDeleteDialogue(dialogue)}
            >
              <img src={trash} alt="Deletar" />
            </button>
          </div>
        </S.Card>
      ))}
    </>
  );
}

DialogueList.propTypes = {
  filteredDialogues: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    input: PropTypes.string.isRequired,
    output: PropTypes.string,
    codeRelation: PropTypes.string,
  })).isRequired,
  orderBy: PropTypes.string.isRequired,
  onToggleOrderBy: PropTypes.func.isRequired,
  onDeleteDialogue: PropTypes.func.isRequired,
};
