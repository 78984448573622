import { useParams, useHistory } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';

import MenuDialoguesService from '../../services/MenuDialoguesService';
import toast from '../../utils/toast';
import useSafeAsyncAction from '../../hooks/useSafeAsyncAction';
import APIError from '../../errors/APIError';
import LoginService from '../../services/LoginService';

export default function useEditMenuDialogue() {
  const [isLoading, setIsLoading] = useState(true);
  const [menuDialogueCodeRelation, setMenuDialogueCodeRelation] = useState('');
  const [chatbotId, setChatbotId] = useState('');
  const [nameMenu, setNameMenu] = useState('');
  const [idMenu, setIdMenu] = useState('');
  const [buttonsArr, setButtonsArr] = useState([]);

  const menuDialogueFormRef = useRef(null);

  const { id, nameMenuParams, idChatbot } = useParams();
  const history = useHistory();
  const safeAsyncAction = useSafeAsyncAction();

  useEffect(() => {
    async function loadMenuDialogue() {
      try {
        const menuDialogue = await MenuDialoguesService.getMenuDialogueByCodeRelation(nameMenuParams, 'asc');
        if (menuDialogue.length > 0) {
          safeAsyncAction(() => {
            menuDialogueFormRef.current.setFieldsValues(menuDialogue[0]);
            setIsLoading(false);
            setMenuDialogueCodeRelation(nameMenuParams);
            setChatbotId(idChatbot);
            setNameMenu(nameMenuParams);
            setIdMenu(id);
            setButtonsArr(menuDialogue);
          });
        } else {
          safeAsyncAction(() => {
            menuDialogueFormRef.current.setFieldsValues('');
            setIsLoading(false);
            setMenuDialogueCodeRelation(nameMenuParams);
            setChatbotId(idChatbot);
            setNameMenu(nameMenuParams);
            setIdMenu(id);
            setButtonsArr([]);
          });
        }
      } catch (error) {
        console.log('error', error);
        safeAsyncAction(() => {
          toast({
            type: 'danger',
            text: 'Menu de Dialogos não encontrado!',
          });
        });
      }
    }

    loadMenuDialogue();
  }, [history, chatbotId, safeAsyncAction, nameMenu, idChatbot, nameMenuParams, id]);

  async function handleSubmit(menuDialogue) {
    try {
      await MenuDialoguesService.editMenuDialogue(idMenu, nameMenu, menuDialogue);
      setMenuDialogueCodeRelation(nameMenu);
      setChatbotId(idChatbot);
      setNameMenu(nameMenuParams);
      toast({
        type: 'success',
        text: 'Menu de dialogos atualizado com sucesso!',
      });
    } catch (error) {
      if (error instanceof APIError && error.body.msg.includes('sessão expirou')) {
        toast({
          type: 'danger',
          text: error.body.msg,
        });
        history.push("/chatbot");
      } else {
        toast({
          type: 'danger',
          text: 'Ocorreu um erro ao tentar atualizar o dialogo.',
        });
      }
    }
  }

  async function handleLogout() {
    await LoginService.logout();
    window.location.href = '/chatbot';
  }

  return {
    isLoading,
    menuDialogueCodeRelation,
    menuDialogueFormRef,
    chatbotId,
    handleSubmit,
    handleLogout,
    buttonsArr,
    nameMenu,
    idMenu,
  };
}
