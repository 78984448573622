import { Link } from 'react-router-dom';
import * as S from './styles';
import arrow from '../../assets/images/icons/arrow.svg';
import PageHeader from '../../components/PageHeader';
import MenuHamburguer from '../../components/MenuHamburguer';

export default function TermosDeUso() {
  return (
    <>
<head>
  <title>Termos de Uso - FBMStore</title>
</head>
<S.Container>
<MenuHamburguer />
<PageHeader
        historyUrl="/chatbot/home"
        title=""
      />
<body>
  <h1>Termos de Uso - FBMStore</h1>

  <h2>1. Introdução</h2>
  <p>Bem-vindo aos Termos de Uso da FBMStore. Ao acessar ou usar nossos serviços, você concorda em cumprir estes termos e todas as leis aplicáveis. Leia atentamente antes de prosseguir.</p>

  <h2>2. Serviços Oferecidos</h2>
  <p>A FBMStore oferece serviços de desenvolvimento de software, consultoria em tecnologia da informação e outros serviços relacionados.</p>

  <h2>3. Uso dos Nossos Serviços</h2>
  <p>Ao utilizar nossos serviços, você concorda em não violar direitos autorais, não acessar nossos sistemas de forma não autorizada e não divulgar informações confidenciais.</p>

  <h2>4. Armazenamento de dados</h2>
  <p>Atualmente só salvamos em nossas bases de dados o CPF(considerado um dado sensível) e o e-mail do usuário para que o sistema tenha um retorno mais rápido nas consultas de pagmanto da assinatura mensal. Não divulgamos nenhum dado pessoal de nossos clientes.</p>

  <h2>5. Limitação de Responsabilidade</h2>
  <p>A FBMStore não se responsabiliza por danos diretos, indiretos, incidentais, especiais, consequenciais ou punitivos decorrentes do uso de nossos serviços.</p>

  <h2>6. Disposições Finais</h2>
  <p>Estes termos podem ser atualizados periodicamente. Ao continuar a usar nossos serviços após tais alterações, você concorda com os termos revisados.</p>

  <p>Data de última atualização: 1º de janeiro de 2024.</p>
</body>
</S.Container>
    </>
  );
}
