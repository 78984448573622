import PageHeader from '../../components/PageHeader';

import SolicitarSenhaService from '../../services/SolicitarSenhaService';
import EmailService from '../../services/EmailService';
import toast from '../../utils/toast';
import SolicitarSenhaForm from '../../components/SolicitarSenhaForm';

export default function SolicitarSenha() {
  async function handleSubmit(formData) {
    try {
      const user = await SolicitarSenhaService.solicitar(formData);
      await EmailService.sendRedefinirSenha(user);

      toast({
        type: 'success',
        text: 'Foi enviado um e-mail com o link para redefinir a sua senha.',
      });

    } catch (error) {
      toast({
        type: 'danger',
        text: 'Ocorreu um erro ao tentar se solicitar link para redefinir senha.',
      });
    }
  }

  return (
    <>
      <PageHeader
        historyUrl="/chatbot"
        title="Solicitar Senha"
      />

      <SolicitarSenhaForm
        buttonLabel="Solicitar redefinição de senha"
        onSubmit={handleSubmit}
      />
    </>
  );
}
