import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';
import useErrors from '../../hooks/userErrors';

import isEmailValid from '../../utils/isEmailValid';

import FormGroup from '../FormGroup';
import Input from '../Input';
import Button from '../Button';

import * as S from './styles';

export default function RedefinirSenhaForm({ buttonLabel, onSubmit, token }) {
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    errors,
    setError,
    removeError,
    getErrorMessageByFieldName,
  } = useErrors();

  const isFormValid = password && passwordConfirm && !errors.length;

  function handlePasswordChange(event) {
    let valor = event.target.value;
    valor.trim();
    setPassword(valor);

    if (!valor) {
      setError({ field: 'password', message: 'Senha é obrigatório.' });
    } else {
      removeError('password');
    }
  }

  function handlePasswordConfirmChange(event) {
    let valor = event.target.value;
    valor.trim();
    setPasswordConfirm(valor);

    if (!valor) {
      setError({ field: 'passwordConfirm', message: 'Confirmação de senha é obrigatório.' });
    } else {
      removeError('passwordConfirm');
    }
  }

 async function handleSubmit(event) {
    event.preventDefault();

    setIsSubmitting(true);

    await onSubmit({
      dadosUser: {
        token, 
        password, 
        passwordConfirm, 
      }
    });

    setIsSubmitting(false);
  }

  return (
    <S.Form onSubmit={handleSubmit} noValidate>
     <FormGroup error={getErrorMessageByFieldName('password')}>
        <Input
          type="password"
          autoComplete="new-password"
          error={getErrorMessageByFieldName('password')}
          placeholder="Senha *"
          value={password}
          onChange={handlePasswordChange}
          disabled={isSubmitting}
        />
      </FormGroup>

      <FormGroup error={getErrorMessageByFieldName('passwordConfirm')}>
        <Input
          type="password"
          autoComplete="new-password"
          error={getErrorMessageByFieldName('passwordConfirm')}
          placeholder="Confirmar Senha *"
          value={passwordConfirm}
          onChange={handlePasswordConfirmChange}
          disabled={isSubmitting}
        />
      </FormGroup>
      
      <S.ButtonContainer>
        <Button
          type="submit"
          disabled={!isFormValid}
          isLoading={isSubmitting}
          onClick={handleSubmit}
        >
          {buttonLabel}
        </Button>
      </S.ButtonContainer>
    </S.Form>
  );
}

RedefinirSenhaForm.propTypes = {
  buttonLabel: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired,
};
