import PageHeader from '../../components/PageHeader';
import SignUpForm from '../../components/SignUpForm';

import SignUpService from '../../services/SignUpService';
import EmailService from '../../services/EmailService';
import toast from '../../utils/toast';

export default function SignUp() {
  async function handleSubmit(formData) {
    try {
    //   const dataSignUp = {
    //     dadosUser: {
    //     email: formData.email,
    //     cpfOrCnpj: formData.cpfOrCnpj,
    //     password: formData.password,
    //     passwordConfirm: formData.passwordConfirm,
    //   }
    // };


      const user = await SignUpService.signup(formData);
      await EmailService.send(user);

      toast({
        type: 'success',
        text: 'Foi enviado um e-mail com os links para concluir o cadastro e com os videos tutoriais sobre sistema.',
      });

      setTimeout(() => {
        window.location.href = '/chatbot';
      }, 10000);
    } catch (error) {
      toast({
        type: 'danger',
        text: 'Ocorreu um erro ao tentar se cadastrar.',
      });
    }
  }

  return (
    <>
      <PageHeader
        historyUrl="/chatbot"
        title="SignUp"
      />

      <SignUpForm
        buttonLabel="Cadastrar e Enviar Link de Pagamento"
        onSubmit={handleSubmit}
      />
    </>
  );
}
