import HttpClient from './utils/HttpClient';

class MinhaContaService {
  constructor() {
    this.httpClient = new HttpClient(process.env.REACT_APP_BASE_URL_API_GTW);
  }

  carregaDados() {
    const t = localStorage.getItem('token');
    const h = { Authorization: `Bearer ${t}` };
    return this.httpClient.get(`/users/dados-conta`, { headers: h });
  }
  
  minhaConta(formData) {
    const t = localStorage.getItem('token');
    const h = { Authorization: `Bearer ${t}` };
    return this.httpClient.post('/users/my-account-update', { body: formData, headers: h });
  }
  
  cancelarAssinatura() {
    const t = localStorage.getItem('token');
    const h = { Authorization: `Bearer ${t}` };
    return this.httpClient.post(`/users/cancelar-assinatura`, { headers: h });
  }
}
export default new MinhaContaService();
