import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import arrow from '../../assets/images/icons/arrow.svg';

import * as S from './styles';

export default function PageHeader({ historyUrl, title }) {
  return (
    <S.Container>
      {title !== 'Login'
        ? (
          <Link to={historyUrl}>
            <img src={arrow} alt="Flecha" />
            <span>Voltar</span>
          </Link>
        ) : null}

      <h1>{title}</h1>
    </S.Container>
  );
}

PageHeader.propTypes = {
  historyUrl: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};
