import styled, { css } from 'styled-components';

export const Form = styled.form``;

export const ButtonContainer = styled.div`
  margin-top: 24px;

  button {
    width: 100%;
  }

  a {
    margin-top: 24px;
    text-decoration: none;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.04);
  height: 52px;
  padding: 0 16px;
  border: none;
  background: ${({ theme }) => theme.colors.primary.main};
  font-size: 16px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.primary.text};
  border-radius: 4px;
  transition: background 0.2s ease-in;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background: ${({ theme }) => theme.colors.primary.light};
  }

  &:active {
    background: ${({ theme }) => theme.colors.primary.dark};
  }

  &[disabled] {
    background: ${({ theme }) => theme.colors.gray[100]} !important;
    cursor: default !important;
  }

  ${({ theme, danger }) => danger && css`
    background: ${theme.colors.danger.main};

    &:hover {
      background: ${theme.colors.danger.light};
    }

    &:active {
      background: ${theme.colors.danger.dark};
    }
  `}
  }
`;

export const InputCardNumber = styled.input`
  width: 100%;
  background: ${({ theme }) => theme.colors.primary.background};
  border: 2px solid ${({ theme }) => theme.colors.primary.background};
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.04);
  height: 52px;
  border-radius: 4px;
  outline: none;
  padding: 0 16px;
  font-size: 16px;
  transition: border-color 0.2s ease-in;
  appearance: none;

  &:focus {
    border-color: ${({ theme }) => theme.colors.primary.main};
  }

  ${({ theme, error }) => error && css`
    color: ${theme.colors.danger.main};
    border-color: ${theme.colors.danger.main} !important;
  `}

  &[disabled] {
    background-color: ${({ theme }) => theme.colors.gray[100]};
    border-color: ${({ theme }) => theme.colors.gray[200]};
  }

`;

export const ContainerInputIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ImageCard = styled.div`
    background-image: url(${({ imageName }) => require(`../../assets/images/icons/${imageName}.png`)});
    background-size: 55px; 
    background-repeat: no-repeat;
    border-radius: 4px;
    width: 55px; 
    height: 52px; 
    position: absolute;
    top: 50%;
    right: 4px; 
    transform: translateY(-50%);
`;
