import PageHeader from '../../components/PageHeader';
import DialogueForm from '../../components/DialogueForm';

import useNewDialogue from './useNewDialogue';
import MenuHamburguer from '../../components/MenuHamburguer';

export default function NewDialogue() {
  const {
    dialogueFormRef,
    idChatbot,
    handleSubmit,
    handleLogout,
  } = useNewDialogue();
  return (
    <>
    <MenuHamburguer />
      <PageHeader
        historyUrl={`/chatbot/config/${idChatbot}`}
        title="Novo Dialogo"
      />
      <p>Lembrete para campo resposta: </p>
      <p>1. chamar outro menu ex.: <b>-menu</b>Nome do Menu</p>
      <p>2. incluir links ex.: <b>linkParam</b> https://fbmstore.com.br <b>linkParam</b> </p>
      <br></br>
      <DialogueForm
        ref={dialogueFormRef}
        chatbotId={idChatbot}
        buttonLabel="Cadastrar"
        onSubmit={handleSubmit}
      />
      {/* <div className="logout">
        <Button onClick={handleLogout}>Sair</Button>
      </div> */}
    </>
  );
}
