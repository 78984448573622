import styled from 'styled-components';

export const Form = styled.form``;

export const ButtonContainer = styled.div`
  margin-top: 24px;
  margin-bottom: 15px;

  button {
    width: 100%;
  }
`;
