import PageHeader from '../../components/PageHeader';
import ChatbotForm from '../../components/ChatbotForm';
import Loader from '../../components/Loader';

import useEditChatbot from './useEditChatbot';
import MenuHamburguer from '../../components/MenuHamburguer';

export default function EditChatbot() {
  const {
    isLoading,
    chatbotName,
    chatbotFormRef,
    handleSubmit,
    handleLogout,
  } = useEditChatbot();

  return (
    <>
    <MenuHamburguer />
      <Loader isLoading={isLoading} />
      <PageHeader
        historyUrl="/chatbot/home"
        title={isLoading ? 'Carregando' : `Editar ${chatbotName}`}
      />

      <ChatbotForm
        ref={chatbotFormRef}
        buttonLabel="Salvar alterações"
        onSubmit={handleSubmit}
      />

      {/* <div className="logout">
        <Button onClick={handleLogout}>Sair</Button>
      </div> */}
    </>
  );
}
