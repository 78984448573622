import PropTypes from 'prop-types';
import { forwardRef } from 'react';

import FormGroup from '../FormGroup';
import Input from '../Input';
import Button from '../Button';

import * as S from './styles';
import useChatbotForm from './useChatbotForm';

const ChatbotForm = forwardRef(({ buttonLabel, onSubmit }, ref) => {
  const {
    handleSubmit,
    getErrorMessageByFieldName,
    codUser,
    isSubmitting,
    nome,
    handleNomeChange,
    telefone,
    handlePhoneChange,
    cidade,
    handleCidadeChange,
    uf,
    handleUfChange,
    bairro,
    handleBairroChange,
    cep,
    handleCepChange,
    logradouro,
    handleLogradouroChange,
    numero,
    handleNumeroChange,
    complemento,
    handleComplementoChange,
    createdAt,
    isFormValid,
  } = useChatbotForm(onSubmit, ref);
  return (
    <S.Form onSubmit={handleSubmit} noValidate>
      <FormGroup error={getErrorMessageByFieldName('codUser')}>
        <Input
          type="hidden"
          error={getErrorMessageByFieldName('codUser')}
          value={codUser}
          disabled={isSubmitting}
        />
      </FormGroup>

      <FormGroup error={getErrorMessageByFieldName('nome')}>
        <Input
          error={getErrorMessageByFieldName('nome')}
          maxLength="50"
          placeholder="Nome *"
          value={nome}
          onChange={handleNomeChange}
          disabled={isSubmitting}
        />
      </FormGroup>

      <FormGroup>
        <Input
          placeholder="Telefone *"
          maxLength="15"
          value={telefone}
          onChange={handlePhoneChange}
          disabled={isSubmitting}
        />
      </FormGroup>

      <FormGroup error={getErrorMessageByFieldName('cidade')}>
        <Input
          error={getErrorMessageByFieldName('cidade')}
          maxLength="50"
          placeholder="Cidade *"
          value={cidade}
          onChange={handleCidadeChange}
          disabled={isSubmitting}
        />
      </FormGroup>

      <FormGroup error={getErrorMessageByFieldName('uf')}>
        <Input
          error={getErrorMessageByFieldName('uf')}
          placeholder="Uf *"
          maxLength="2"
          value={uf}
          onChange={handleUfChange}
          disabled={isSubmitting}
        />
      </FormGroup>

      <FormGroup error={getErrorMessageByFieldName('bairro')}>
        <Input
          error={getErrorMessageByFieldName('bairro')}
          maxLength="50"
          placeholder="Bairro *"
          value={bairro}
          onChange={handleBairroChange}
          disabled={isSubmitting}
        />
      </FormGroup>

      <FormGroup error={getErrorMessageByFieldName('cep')}>
        <Input
          maxLength="9"
          error={getErrorMessageByFieldName('cep')}
          placeholder="CEP *"
          value={cep}
          onChange={handleCepChange}
          disabled={isSubmitting}
        />
      </FormGroup>

      <FormGroup error={getErrorMessageByFieldName('logradouro')}>
        <Input
          error={getErrorMessageByFieldName('logradouro')}
          maxLength="150"
          placeholder="Avenida, rua, estrada ... *"
          value={logradouro}
          onChange={handleLogradouroChange}
          disabled={isSubmitting}
        />
      </FormGroup>

      <FormGroup error={getErrorMessageByFieldName('numero')}>
        <Input
          error={getErrorMessageByFieldName('numero')}
          maxLength="10"
          placeholder="Número *"
          value={numero}
          onChange={handleNumeroChange}
          disabled={isSubmitting}
        />
      </FormGroup>

      <FormGroup error={getErrorMessageByFieldName('complemento')}>
        <Input
          error={getErrorMessageByFieldName('complemento')}
          maxLength="130"
          placeholder="Complemento"
          value={complemento}
          onChange={handleComplementoChange}
          disabled={isSubmitting}
        />
      </FormGroup>

      <FormGroup error={getErrorMessageByFieldName('createdAt')}>
        <Input
          type="hidden"
          error={getErrorMessageByFieldName('createdAt')}
          value={createdAt}
          disabled={isSubmitting}
        />
      </FormGroup>

      <S.ButtonContainer>
        <Button
          type="submit"
          disabled={!isFormValid}
          isLoading={isSubmitting}
        >
          {buttonLabel}
        </Button>
      </S.ButtonContainer>
    </S.Form>
  );
});

ChatbotForm.propTypes = {
  buttonLabel: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default ChatbotForm;
