import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import arrow from '../../../../assets/images/icons/arrow.svg';
import config from '../../../../assets/images/icons/config.svg';
import edit from '../../../../assets/images/icons/edit.svg';
import trash from '../../../../assets/images/icons/trash.svg';

import * as S from './styles';

export default function ChatbotList({
  filteredChatbots,
  orderBy,
  onToggleOrderBy,
  onDeleteChatbot,
}) {
  return (
    <>
      {filteredChatbots.length > 0 && (
        <S.ListHeader orderBy={orderBy}>
          <button type="button" onClick={onToggleOrderBy}>
            <span>Nome</span>
            <img src={arrow} alt="Flecha" />
          </button>
        </S.ListHeader>
      )}

      {filteredChatbots.map((chatbot) => (
        <S.Card key={chatbot.id}>
          <div className="info">
            <div className="chatbot-name">
              <strong>{chatbot.nome}</strong>
              {/* {chatbot.category_name && <small>{chatbot.category_name}</small>} */}
            </div>
            <span>{chatbot.bairro}</span>
            <span>{chatbot.telefone}</span>
          </div>

          <div className="actions">
            <Link to={`/chatbot/config/${chatbot.id}`}>
              <img src={config} alt="Configurações" />
            </Link>
            <Link to={`/chatbot/edit/${chatbot.id}`}>
              <img src={edit} alt="Editar" />
            </Link>
            <button
              type="button"
              onClick={() => onDeleteChatbot(chatbot)}
            >
              <img src={trash} alt="Deletar" />
            </button>
          </div>
        </S.Card>
      ))}
    </>
  );
}

ChatbotList.propTypes = {
  filteredChatbots: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    nome: PropTypes.string.isRequired,
    bairro: PropTypes.string,
    telefone: PropTypes.string,
  })).isRequired,
  orderBy: PropTypes.string.isRequired,
  onToggleOrderBy: PropTypes.func.isRequired,
  onDeleteChatbot: PropTypes.func.isRequired,
};
