import styled, { keyframes, css } from 'styled-components';

const fadeIn = keyframes`
from {opacity: 0;}
to {opacity: 1;}
`;

const fadeOut = keyframes`
from {opacity: 1;}
to {opacity: 0;}
`;

const scaleIn = keyframes`
from {transform: scale(0);}
to {transform: scale(1);}
`;

const scaleOut = keyframes`
from {transform: scale(1);}
to {transform: scale(0);}
`;

export const Overlay = styled.div`
  /* position: fixed;
  width: 410px;
  height: 450px;
  bottom: 20px;
  right: 0;
  margin-right: 20px;
  display: flex;
  align-items: center;
  justify-content: center; */
  animation: ${fadeIn} 0.3s;

  ${({ isLeaving }) => isLeaving && css`animation: ${fadeOut} 1s forwards;`}
`;

export const Container = styled.div`
  /* width: 410px;
  height: 450px;
  max-width: 410px;
  max-height: 450px;
  background: ${({ theme }) => theme.colors.background};
  padding: 24px;
  border: solid 1px rgba(0, 0, 0, 0.4);
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.4); */
  animation: ${scaleIn} 0.3s;

  ${({ isLeaving }) => isLeaving && css`animation: ${scaleOut} 0.3s forwards;`}

  /* > h1 {
    font-size: 22px;
    color: ${({ theme, danger }) => (
    danger
      ? theme.colors.danger.main
      : theme.colors.gray[900]
  )}
  }

  .modal-body {
    margin-top: 32px;
  } */

`;

export const Footer = styled.footer`
  margin-top: 32px;

  display: flex;
  align-items: center;
  justify-content: flex-end;

  .cancel-button {
    background: transparent;
    border: none;
    font-size: 16px;
    margin-right: 24px;
    color: ${({ theme }) => theme.colors.gray[200]};

    &[disabled] {
    cursor: not-allowed !important;
   }
  }
`;
