import { useParams, useHistory } from 'react-router-dom';
import {
  useEffect, useState, useMemo, useCallback,
} from 'react';
import MenuDialoguesService from '../../../services/MenuDialoguesService';
import toast from '../../../utils/toast';
import LoginService from '../../../services/LoginService';
import DialoguesService from '../../../services/DialoguesService';
import { useGlobalState } from '../../../assets/state';

export default function useMenuDialogue() {
  const { id } = useParams();
  const history = useHistory();
  const [menuDialogues, setMenuDialogues] = useState([]);
  const [orderBy, setOrderBy] = useState('asc');
  const [searchTerm, setSearchTerm] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [menuDialogueBeingDelete, setMenuDialogueBeingDelete] = useState(null);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const [hasWellcome] = useGlobalState('hasWellcome');

  const filteredMenuDialogues = useMemo(() => menuDialogues.filter((el) => (
    (el.nameMenu.toLowerCase().includes(searchTerm.toLowerCase()))
  )), [menuDialogues, searchTerm]);

  const loadMenuDialogues = useCallback(async () => {
    try {
      setIsLoading(true);

      const menuDialoguesList = await MenuDialoguesService.listMenuDialogues(id, orderBy);

      const filtro = menuDialoguesList.filter((el) => !el
        .nameMenu.includes('WellCome'));
      setHasError(false);
      setMenuDialogues(filtro);
    } catch (e) {
      setHasError(true);
      setMenuDialogues([]);
      history.push('/chatbot');
    } finally {
      setIsLoading(false);
    }
  }, [id, orderBy]);

  useEffect(() => {
    loadMenuDialogues();
  }, [loadMenuDialogues]);

  async function getDialogue(idChatbot, input, flag) {
    try {
      setIsLoading(true);

      if (input === '') {
        return 'Para poder te ajudar preciso que descreva a sua dúvida, sem texto não consigo adivinhar';
      }

      const dialogue = await DialoguesService
        .getDialogueByIdChatbotAndInput(idChatbot, input, flag);

      return dialogue;
    } catch {
      return 'Não sei te responder';
    } finally {
      setIsLoading(false);
    }
  }

  function handleToggleOrderBy() {
    setOrderBy(
      (prevState) => (prevState === 'asc' ? 'desc' : 'asc'),
    );
  }

  function handleChangeSearchTerm(event) {
    setSearchTerm(event.target.value);
  }

  function handleTryAgain() {
    loadMenuDialogues();
  }

  function handleDeleteMenuDialogue(menuDialogue) {
    setMenuDialogueBeingDelete(menuDialogue);
    setIsDeleteModalVisible(true);
  }

  function handleIdChatbot() {
    return id;
  }

  function handleCloseDeleteModal() {
    setIsDeleteModalVisible(false);
  }

  async function handleConfirmDeleteMenuDialogue() {
    try {
      setIsLoadingDelete(true);
      await MenuDialoguesService
        .deleteMenuDialogue(menuDialogueBeingDelete.nameMenu, id);

      setMenuDialogues((prevState) => prevState.filter(
        (menuDialogue) => menuDialogue.nameMenu !== menuDialogueBeingDelete.nameMenu,
      ));

      handleCloseDeleteModal();

      toast({
        type: 'success',
        text: 'menu de dialogos excluído com sucesso',
      });
    } catch {
      history.goBack();
      toast({
        type: 'danger',
        text: 'Ocorreu um erro ao tentar excluir o menu de dialogos',
      });
    } finally {
      setIsLoadingDelete(false);
    }
  }

  async function handleLogout() {
    await LoginService.logout();
    window.location.href = '/chatbot';
  }

  return {
    isLoading,
    isLoadingDelete,
    isDeleteModalVisible,
    handleCloseDeleteModal,
    menuDialogueBeingDelete,
    handleConfirmDeleteMenuDialogue,
    menuDialogues,
    searchTerm,
    handleChangeSearchTerm,
    hasError,
    filteredMenuDialogues,
    handleTryAgain,
    orderBy,
    handleToggleOrderBy,
    handleDeleteMenuDialogue,
    handleIdChatbot,
    handleLogout,
    getDialogue,
    hasWellcome,
  };
}
