import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';

import arrow from '../../../../assets/images/icons/arrow.svg';
import Button from '../../../../components/Button';
import Loader from '../../../../components/Loader';
import APIError from '../../../../errors/APIError';
import toast from '../../../../utils/toast';
import ModalChatbot from './components/ModalChatbot';

import { Container, HeaderMenuDialogues } from './styles';
import RedirectButton from '../../../../components/RedirectButton';

export default function HeaderMenuDialogue({
  hasError,
  qtyOfMenuDialogues,
  qtyOfFilteredMenuDialogues,
  historyUrl,
  handleIdChatbot,
  getDialogue,
  isVisibleHamburguer,
  hasWellcome,
}) {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  
  useEffect(() => {
    const checkScreenWidth = () => {
      if (window.innerWidth <= 768) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };

    checkScreenWidth();
  }, []);

  function handleCloseModalChatbot() {
    setIsModalVisible(false);
    isVisibleHamburguer(false);
  }

  function handleModalChatbot() {
    isVisibleHamburguer(true);
    setIsLoading(true);
    setIsModalVisible(true);
    if (hasWellcome.length > 0) {
      setTimeout(() => {
        addChildMenuWellcome(hasWellcome);
      }, 2000);
    } else {
      setIsLoading(false);
    }
  }

  const idChatbot = handleIdChatbot();

  async function addChildMenuWellcome(arrayWellcome) {
    const divOtherMenu = document.createElement('div');
    divOtherMenu.setAttribute('class', 'menu-wellcome');
    const ul = document.createElement('ul');
    await arrayWellcome.map((el) => {
      const li = document.createElement('li');
      li.textContent = el.input;
      li.addEventListener('click', () => addChildByQuestion(el.input, '-menu'));
      ul.appendChild(li);
    });
    const msg = document.getElementById('msg');
    divOtherMenu.appendChild(ul);
    msg.appendChild(divOtherMenu);

    document.getElementById('input').value = '';
    document.getElementById('input').focus();
    setIsLoading(false);
  }

  async function addChildByQuestion(question, flag) {
    const msg = document.getElementById('msg');
    const linkEnd = document.createElement('a');
    linkEnd.setAttribute('href', '#');
    linkEnd.setAttribute('id', 'end');

    const divOtherMenu = document.createElement('div');
    divOtherMenu.setAttribute('class', 'menu-wellcome');

    try {
      const output = await getDialogue(idChatbot, question, flag);

      if (output.length === 1 && output[0].output.includes('-menu')) {
        output[0].output.replace('-menu', '');
        const end = document.getElementById('end');
        if (end) {
          msg.removeChild(end);
        }

        const divQuestion = document.createElement('div');
        divQuestion.setAttribute('class', 'talk-bubble tri-right right-top');

        const divTalkTextQuestion = document.createElement('div');
        divTalkTextQuestion.setAttribute('class', 'talktext-input');

        const paragrafoQuestion = document.createElement('p');
        paragrafoQuestion.textContent = question;

        msg.appendChild(divQuestion);
        divQuestion.appendChild(divTalkTextQuestion);
        divTalkTextQuestion.appendChild(paragrafoQuestion);

        const ul = document.createElement('ul');
        await output.map((el) => {
          const li = document.createElement('li');
          li.textContent = el.input;
          li.addEventListener('click', () => addChildByQuestion(el.input, '-menu'));
          ul.appendChild(li);
        });

        const linkEnd = document.createElement('a');
        linkEnd.setAttribute('href', '#');
        linkEnd.setAttribute('id', 'end');

        divOtherMenu.appendChild(ul);
        msg.appendChild(divOtherMenu);
        msg.appendChild(linkEnd);
      } else if (output.length > 1) {
        const end = document.getElementById('end');
        if (end) {
          msg.removeChild(end);
        }

        const divQuestion = document.createElement('div');
        divQuestion.setAttribute('class', 'talk-bubble tri-right right-top');

        const divTalkTextQuestion = document.createElement('div');
        divTalkTextQuestion.setAttribute('class', 'talktext-input');

        const paragrafoQuestion = document.createElement('p');
        paragrafoQuestion.textContent = question;

        msg.appendChild(divQuestion);
        divQuestion.appendChild(divTalkTextQuestion);
        divTalkTextQuestion.appendChild(paragrafoQuestion);

        const ul = document.createElement('ul');
        await output.map((el) => {
          const li = document.createElement('li');
          li.textContent = el.input;
          li.addEventListener('click', () => addChildByQuestion(el.input, '-menu'));
          ul.appendChild(li);
        });

        const linkEnd = document.createElement('a');
        linkEnd.setAttribute('href', '#');
        linkEnd.setAttribute('id', 'end');

        divOtherMenu.appendChild(ul);
        msg.appendChild(divOtherMenu);
        msg.appendChild(linkEnd);
      } else {
        if(output[0].output.includes('linkParam')) {
                let linkParam = "linkParam";
                let contador = 1;
                let contador2 = 1;
                let linkOrWord = "";
                let outputInit = output[0].output.substring(0, output[0].output.indexOf(linkParam));
                let outputFinish = output[0].output.substring((output[0].output.lastIndexOf(linkParam) + 9));

              const end = document.getElementById('end');
              if (end) {
                msg.removeChild(end);
              }
              const divQuestion = document.createElement('div');
              divQuestion.setAttribute('class', 'talk-bubble tri-right right-top');

              const divTalkTextQuestion = document.createElement('div');
              divTalkTextQuestion.setAttribute('class', 'talktext-input');

              const paragrafoQuestion = document.createElement('p');
              paragrafoQuestion.textContent = question;

              msg.appendChild(divQuestion);
              divQuestion.appendChild(divTalkTextQuestion);
              divTalkTextQuestion.appendChild(paragrafoQuestion);

              const divResponse = document.createElement('div');
              divResponse.setAttribute('class', 'talk-bubble tri-right left-top');

              const divTalkTextResponse = document.createElement('div');
              divTalkTextResponse.setAttribute('class', 'talktext-output');

              // const paragrafoResponse = document.createElement('p');
              // paragrafoResponse.textContent = output[0] ? output[0].output : 'Erro inesperado, caso continue favor entrar em contato com o administrador pelo e-mail: frontbackmobile@gmail.com';
              
              // inicio
              const paragrafoResponse = document.createElement('p');

                  var outputElement = document.createElement('span');
                  outputElement.textContent = outputInit;
                  paragrafoResponse.appendChild(outputElement);

                  for (let index = output[0].output.indexOf(linkParam);index >= 0; index = output[0].output.indexOf(linkParam, index + 1)) {
            
                      linkOrWord = output[0].output.substring(index + 9, output[0].output.indexOf(linkParam, index + 1));
                      if(linkOrWord.includes("http") && index != output[0].output.lastIndexOf(linkParam)){
                        
                        contador += 1;
                        
                        outputElement = document.createElement('a');
                        outputElement.setAttribute('href', linkOrWord);
                        outputElement.textContent = linkOrWord;
                        
                        paragrafoResponse.appendChild(outputElement);
                      } else if(!linkOrWord.includes("http") && index != output[0].output.lastIndexOf(linkParam)) {
                        
                        contador2 += 1;
                        
                        outputElement = document.createElement('span');
                        outputElement.textContent = linkOrWord;
                        
                        paragrafoResponse.appendChild(outputElement);
                      }
            }
              outputElement = document.createElement('span');
              outputElement.textContent = outputFinish;
              
              paragrafoResponse.appendChild(outputElement);
              //  fim

              msg.appendChild(divResponse);
              divResponse.appendChild(divTalkTextResponse);
              divTalkTextResponse.appendChild(paragrafoResponse);

              const linkEnd = document.createElement('a');
              linkEnd.setAttribute('href', '#');
              linkEnd.setAttribute('id', 'end');

              msg.appendChild(linkEnd);
      } else {
        const end = document.getElementById('end');
        if (end) {
          msg.removeChild(end);
        }
        const divQuestion = document.createElement('div');
        divQuestion.setAttribute('class', 'talk-bubble tri-right right-top');

        const divTalkTextQuestion = document.createElement('div');
        divTalkTextQuestion.setAttribute('class', 'talktext-input');

        const paragrafoQuestion = document.createElement('p');
        paragrafoQuestion.textContent = question;

        msg.appendChild(divQuestion);
        divQuestion.appendChild(divTalkTextQuestion);
        divTalkTextQuestion.appendChild(paragrafoQuestion);

        const divResponse = document.createElement('div');
        divResponse.setAttribute('class', 'talk-bubble tri-right left-top');

        const divTalkTextResponse = document.createElement('div');
        divTalkTextResponse.setAttribute('class', 'talktext-output');

        const paragrafoResponse = document.createElement('p');
        paragrafoResponse.textContent = output[0] ? output[0].output : 'Erro inesperado, caso continue favor entrar em contato com o administrador pelo e-mail: frontbackmobile@gmail.com';

        msg.appendChild(divResponse);
        divResponse.appendChild(divTalkTextResponse);
        divTalkTextResponse.appendChild(paragrafoResponse);

        const linkEnd = document.createElement('a');
        linkEnd.setAttribute('href', '#');
        linkEnd.setAttribute('id', 'end');

        msg.appendChild(linkEnd);
      }
      }

      document.getElementById('input').value = '';
      window.location.href = '#end';
      document.getElementById('input').focus();
    } catch (error) {
      if (error instanceof APIError && error.body.msg.includes('sessão expirou')) {
        toast({
          type: 'danger',
          text: error.body.msg,
        });
        history.push("/chatbot");
      } else {
        toast({
          type: 'danger',
          text: 'Erro inesperado, contate o administrador do sistema.',
        });
      }
    }
  }

  function send(e) {
    if (e.keyCode === 13) {
      const Input = document.getElementById('input');
      const question = Input.value.toString().trim();
      if(question == ""){
        alert('Digite algo para poder pesquisar!');
      } else {
      addChildByQuestion(question, 'input');
      return false;
      }
    }
  }

  function sendByBtn() {
    const Input = document.getElementById('input');
    const question = Input.value.toString().trim();
    if(question == ""){
      alert('Digite algo para poder pesquisar!');
    } else {
    addChildByQuestion(question, 'input');
    return false;
    }
}

  const alignment = hasError
    ? 'flex-end'
    : (
      qtyOfMenuDialogues > 0
        ? 'space-between'
        : 'center'
    );

  return (
    <>
      <HeaderMenuDialogues>
        <Link to={`${historyUrl}/${idChatbot}`}>
          <img src={arrow} alt="Flecha" />
          <span>Voltar</span>
        </Link>
        {isMobile ? 
        <RedirectButton idChatbot={idChatbot} currentUrl={`/chatbot/outros/menus/dialogue/${idChatbot}`} targetUrl="/chatbot/chatbot-page">
          <span>Testar Chatbot</span>
        </RedirectButton> :
        <Button onClick={handleModalChatbot}>
          <span>Testar Chatbot</span>
        </Button>}
      </HeaderMenuDialogues>
      <Container
        justifyContent={alignment}
      >
        {(!hasError && qtyOfMenuDialogues > 0) && (
          <strong>
            {qtyOfFilteredMenuDialogues}
            {' '}
            {qtyOfFilteredMenuDialogues === 1 ? 'menu de dialogo' : 'menus de dialogos'}

          </strong>
        )}
        <Link to={`/chatbot/new/menu/dialogue/${idChatbot}`}>Novo Menu</Link>
      </Container>
      <Loader isLoading={isLoading} />
      <ModalChatbot
        isLoading={isLoading}
        visible={isModalVisible}
        title="Bem vindo, em que posso ajudar ?"
        confirmLabel="Enviar"
        onCancel={handleCloseModalChatbot}
      >
        <div className={isMobile ? "box-dialogue-mobile" : "box-dialogue"}>
        <div className="header-box">
            <div className="dot-strong">
              <span className="dot">
                &nbsp;
              </span>
              <strong>Chatbot está online</strong>
            </div>
            <div className="div-myButton">
              <span className="myButton" onClick={handleCloseModalChatbot} />
            </div>
          </div>
          <div className={isMobile ? "body-box-mobile" : "body-box"}>
            <div className={isMobile ? "scroll-messages-mobile" : "scroll-messages"} id="msg">
              <div className="talk-bubble tri-right left-top">
                <div className="talktext-output">
                  <p>
                    {hasWellcome.length > 0 ? 'Escolha nossas opções abaixo!' : 'Olá! Em que posso ajudar?'}
                    {' '}
                  </p>
                </div>
              </div>
            </div>
            <div className="input-message-btn">
            <input
              className="input-message"
              type="text"
              id="input"
              autoFocus
              onKeyDown={(e) => send(e)}
              placeholder="Digite a sua mensagem e tecle Enter..."
            />
              <button class="send-button" onClick={sendByBtn}>
                  <div class="triangle"></div>
              </button>
            </div>
          </div>
          {/* <input type="hidden" id="code_user" value="[code_user]" />
          <input type="hidden" id="code_before" value="0" /> */}
        </div>
      </ModalChatbot>
    </>
  );
}

HeaderMenuDialogue.propTypes = {
  hasError: PropTypes.bool.isRequired,
  qtyOfMenuDialogues: PropTypes.number.isRequired,
  qtyOfFilteredMenuDialogues: PropTypes.number.isRequired,
  historyUrl: PropTypes.string.isRequired,
  handleIdChatbot: PropTypes.func.isRequired,
  getDialogue: PropTypes.func.isRequired,
  isVisibleHamburguer: PropTypes.func.isRequired,
  hasWellcome: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    input: PropTypes.string.isRequired,
    output: PropTypes.string,
    codeRelation: PropTypes.string,
  })),
};

HeaderMenuDialogue.defaultProps = {
  hasWellcome: [],
};
