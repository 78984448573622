import { useState } from 'react';
import Button from '../../../components/Button';
import Loader from '../../../components/Loader';
import MenuHamburguer from '../../../components/MenuHamburguer';
import Modal from '../../../components/Modal';
import EmptyMenuList from '../components/EmptyMenuList';
import ErrorStatus from '../components/ErrorStatus';
import HeaderMenuDialogue from '../components/HeaderMenuDialogue';
import InputSearch from '../components/InputSearch';
import MenuDialogueList from '../components/MenuDialogueList';
import SearchNotFound from '../components/SearchNotFound';
import * as S from './styles';

import useMenuDialogue from './useMenuDialogue';

export default function MenuDialogues() {
  const {
    isLoading,
    isLoadingDelete,
    isDeleteModalVisible,
    menuDialogueBeingDelete,
    handleCloseDeleteModal,
    handleConfirmDeleteMenuDialogue,
    menuDialogues,
    searchTerm,
    handleChangeSearchTerm,
    hasError,
    filteredMenuDialogues,
    handleTryAgain,
    orderBy,
    handleToggleOrderBy,
    handleDeleteMenuDialogue,
    handleIdChatbot,
    getDialogue,
    menuDialogueOutput,
    handleLogout,
    hasWellcome,
  } = useMenuDialogue();

  const [isVisible, setIsVisible] = useState(false);

  const hasMenuDialogues = menuDialogues.length > 0;
  const isListEmpty = !hasError && (!isLoading && !hasMenuDialogues);
  const isSearchEmpty = !hasError && (hasMenuDialogues && filteredMenuDialogues.length < 1);

  function isVisibleHamburguer(valor) {
    setIsVisible(valor);
  }

  return (
    <S.Container>
      <MenuHamburguer isVisible={isVisible}/>
      <Loader isLoading={isLoading} />

      {hasMenuDialogues && (
        <InputSearch
          value={searchTerm}
          onChange={handleChangeSearchTerm}
        />
      )}

      <HeaderMenuDialogue
        hasError={hasError}
        qtyOfMenuDialogues={menuDialogues.length}
        qtyOfFilteredMenuDialogues={filteredMenuDialogues.length}
        historyUrl="/chatbot/config"
        handleIdChatbot={() => handleIdChatbot()}
        getDialogue={getDialogue}
        isVisibleHamburguer={isVisibleHamburguer}
        menuDialogueOutput={menuDialogueOutput}
        hasWellcome={hasWellcome}
      />

      {hasError && (<ErrorStatus onTryAgain={handleTryAgain} />)}
      {isListEmpty && <EmptyMenuList />}
      {isSearchEmpty && <SearchNotFound searchTerm={searchTerm} />}

      {hasMenuDialogues && (
        <>
          <MenuDialogueList
            filteredMenuDialogues={filteredMenuDialogues}
            orderBy={orderBy}
            onToggleOrderBy={handleToggleOrderBy}
            onDeleteMenuDialogue={handleDeleteMenuDialogue}
          />

          <Modal
            danger
            isLoading={isLoadingDelete}
            visible={isDeleteModalVisible}
            title={`Tem certeza que deseja remover o menu de dialogos "${menuDialogueBeingDelete?.nameMenu}"?`}
            confirmLabel="Deletar"
            onCancel={handleCloseDeleteModal}
            onConfirm={handleConfirmDeleteMenuDialogue}
          >
            <h2>Esta ação não poderá ser desfeita</h2>
          </Modal>

          {/* <div className="logout">
            <Button onClick={handleLogout}>Sair</Button>
          </div> */}
        </>
      )}

    </S.Container>
  );
}
