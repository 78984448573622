import { useParams, useHistory } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';

import DialoguesService from '../../services/DialoguesService';
import toast from '../../utils/toast';
import useSafeAsyncAction from '../../hooks/useSafeAsyncAction';
import APIError from '../../errors/APIError';
import LoginService from '../../services/LoginService';

export default function useEditDialogue() {
  const [isLoading, setIsLoading] = useState(true);
  const [dialogueInput, setDialogueInput] = useState('');
  const [chatbotId, setChatbotId] = useState('');

  const dialogueFormRef = useRef(null);

  const { id } = useParams();
  const history = useHistory();
  const safeAsyncAction = useSafeAsyncAction();

  useEffect(() => {
    async function loadDialogue() {
      try {
        const dialogue = await DialoguesService.getDialogueById(id);

        safeAsyncAction(() => {
          dialogueFormRef.current.setFieldsValues(dialogue);
          setIsLoading(false);
          setDialogueInput(dialogue.input);
          setChatbotId(dialogue.idChatbot);
        });
      } catch (error) {
        safeAsyncAction(() => {
          history.push(`/chatbot/config/${chatbotId}`);
          toast({
            type: 'danger',
            text: 'Dialogo não encontrado!',
          });
        });
      }
    }

    loadDialogue();
  }, [id, history, chatbotId, safeAsyncAction]);

  async function handleSubmit(dialogue) {
    try {
      const dialogueData = await DialoguesService.editDialogue(id, dialogue);
      setDialogueInput(dialogueData.input);
      setChatbotId(dialogueData.idChatbot);
      toast({
        type: 'success',
        text: 'Dialogo atualizado com sucesso!',
      });
    } catch (error) {
      if (error instanceof APIError && error.body.msg.includes('sessão expirou')) {
        toast({
          type: 'danger',
          text: error.body.msg,
        });
        history.push("/chatbot");
      } else {
        toast({
          type: 'danger',
          text: 'Ocorreu um erro ao tentar atualizar o dialogo.',
        });
      }
    }
  }

  async function handleLogout() {
    await LoginService.logout();
    window.location.href = '/chatbot';
  }

  return {
    isLoading,
    dialogueInput,
    dialogueFormRef,
    chatbotId,
    handleSubmit,
    handleLogout,
  };
}
