import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';
import useErrors from '../../hooks/userErrors';

import isEmailValid from '../../utils/isEmailValid';

import FormGroup from '../FormGroup';
import Input from '../Input';
import Button from '../Button';

import * as S from './styles';
import MinhaContaService from '../../services/MinhaContaService';

export default function MinhaContaForm({ buttonLabel, onSubmit }) {
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoadingDadosUser, setIsLoadingDadosUser] = useState(true);
  const [dadosUser, setDadosUser] = useState({
    email: '',
  });

  const {
    errors,
    setError,
    removeError,
    getErrorMessageByFieldName,
  } = useErrors();

  
  useEffect(async () => {
    try {
      const userDados = await MinhaContaService.carregaDados();
      setDadosUser(userDados.dadosUser);
    } catch(err) {
      console.log(err);
    } finally {
      setIsLoadingDadosUser(false);
    }
  }, []);
  
  const isFormValid = dadosUser.email && !errors.length;

  function handleEmailChange(event) {
    setDadosUser(
      {
        email: event.target.value,
      }
    );

    if (event.target.value && !isEmailValid(event.target.value)) {
      setError({ field: 'email', message: 'E-mail inválido.' });
    } else {
      removeError('email');
    }
  }

  // function handleCpfOrCnpjChange(event) {
  //   let valor = event.target.value.length > 14 ? formatCnpj(event.target.value) : formatCpf(event.target.value);

  //   setCpfOrCnpj(valor);

  //   if (valor && !isCpfOrCnpjValid(valor)) {
  //     setError({ field: 'cpfOrCnpj', message: 'Cpf ou Cnpj inválido.' });
  //   } else {
  //     removeError('cpfOrCnpj');
  //   }
  // }

  function handlePasswordChange(event) {
    setPassword(event.target.value);

    if (event.target.value.length > 0 && event.target.value !== passwordConfirm) {
      setError({ field: 'password', message: 'Senhas não são identicas.' });
      return;
    } 
    if (event.target.value === passwordConfirm) {
      removeError('password');
      removeError('passwordConfirm');
    } 
  }

  function handlePasswordConfirmChange(event) {
    setPasswordConfirm(event.target.value);

    if (event.target.value.length > 0 && event.target.value !== password) {
      setError({ field: 'passwordConfirm', message: 'Senhas não são identicas.' });
      return;
    } 
    if (event.target.value === password) {
      removeError('password');
      removeError('passwordConfirm');
    }
  }

  async function handleSubmit(event) {
    event.preventDefault();

    if(password.length == 0 && passwordConfirm.length == 0){
      setError({ field: 'password', message: 'Senha é obrigatório.' });
      setError({ field: 'passwordConfirm', message: 'Confirmação de senha é obrigatório.' });
      return;
    }
    if(password.length == 0 && passwordConfirm.length > 0){
      setError({ field: 'password', message: 'Senha é obrigatório.' });
      return;
    }
    if(password.length > 0 && passwordConfirm.length == 0){
      setError({ field: 'passwordConfirm', message: 'Confirmação de senha é obrigatório.' });
      return;
    }

    setIsSubmitting(true);

    await onSubmit({
      dadosUser: {
        email: dadosUser.email, password, passwordConfirm
      }
    });

    setIsSubmitting(false);
  }

  return (
    <S.Form onSubmit={handleSubmit} noValidate>
      <FormGroup isLoading={isLoadingDadosUser} error={getErrorMessageByFieldName('email')}>
        <Input
          type="text"
          error={getErrorMessageByFieldName('email')}
          placeholder="E-mail"
          value={dadosUser.email}
          onChange={handleEmailChange}
          onFocus={handleEmailChange}
          disabled={isSubmitting}
        />
      </FormGroup>
      
      <FormGroup error={getErrorMessageByFieldName('password')}>
        <Input
          type="password"
          autoComplete="new-password"
          error={getErrorMessageByFieldName('password')}
          placeholder="Senha *"
          value={password}
          onChange={handlePasswordChange}
          onFocus={handlePasswordChange}
          onBlur={handlePasswordChange}
          disabled={isSubmitting}
        />
      </FormGroup>

      <FormGroup error={getErrorMessageByFieldName('passwordConfirm')}>
        <Input
          type="password"
          autoComplete="new-password" 
          error={getErrorMessageByFieldName('passwordConfirm')}
          placeholder="Confirmar Senha *"
          value={passwordConfirm}
          onChange={handlePasswordConfirmChange}
          onFocus={handlePasswordConfirmChange}
          onBlur={handlePasswordConfirmChange}
          disabled={isSubmitting}
        />
      </FormGroup>

      <S.ButtonContainer>
        <Button
          type="submit"
          disabled={!isFormValid}
          isLoading={isSubmitting}
        >
          {buttonLabel}
        </Button>
      </S.ButtonContainer>
    </S.Form>
  );
}

MinhaContaForm.propTypes = {
  buttonLabel: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  dadosUser: PropTypes.object,
};
