import HttpClient from './utils/HttpClient';

class EmailService {
  constructor() {
    this.httpClient = new HttpClient(process.env.REACT_APP_BASE_URL_API_GTW);
  }

  send(email) {
    return this.httpClient.post('/emails/signup', { body: email });
  }
  
  sendRedefinirSenha(user) {
    return this.httpClient.post('/emails/solicitar-senha', { body: user });
  }
}
export default new EmailService();
