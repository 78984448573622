class DialogueMapper {
  toPersistence(domainDialogue) {
    return {
      id: domainDialogue?.id,
      codUser: domainDialogue?.codUser,
      idChatbot: domainDialogue?.idChatbot,
      codeCurrent: domainDialogue.codeCurrent,
      codeRelation: domainDialogue.codeRelation,
      codeBefore: domainDialogue.codeBefore,
      input: domainDialogue.input,
      output: domainDialogue.output,
      createdAt: domainDialogue?.createdAt,
    };
  }

  toDomain(persistenceDialogue) {
    return {
      id: persistenceDialogue?._id,
      codUser: persistenceDialogue?.codUser,
      idChatbot: persistenceDialogue?.idChatbot,
      codeCurrent: persistenceDialogue.codeCurrent,
      codeRelation: persistenceDialogue.codeRelation,
      codeBefore: persistenceDialogue.codeBefore,
      input: persistenceDialogue.input,
      output: persistenceDialogue.output,
      createdAt: persistenceDialogue?.createdAt,
      updatedAt: persistenceDialogue?.updatedAt,
    };
  }
}
export default new DialogueMapper();
