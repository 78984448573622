import React from 'react';
import ReactDOM from 'react-dom';

import Site from './components/Site';
import App from './components/App';

ReactDOM.render(
  <React.StrictMode>
     {window.location.href === process.env.REACT_APP_BASE_URL ? <Site /> : <App />}
  </React.StrictMode>,
  document.getElementById('root'),
);
