import * as S from './styles';

import Loader from '../../components/Loader';
import Modal from '../../components/Modal';
import useDialogue from './useDialogue';
import InputSearch from './components/InputSearch';
import Header from './components/Header';
import ErrorStatus from './components/ErrorStatus';
import EmptyList from './components/EmptyList';
import SearchNotFound from './components/SearchNotFound';
import DialogueList from './components/DialogueList';
import MenuHamburguer from '../../components/MenuHamburguer';
import { useState } from 'react';

export default function Dialogues() {
  const {
    isLoading,
    isLoadingDelete,
    isDeleteModalVisible,
    dialogueBeingDelete,
    handleCloseDeleteModal,
    handleConfirmDeleteDialogue,
    dialogues,
    searchTerm,
    handleChangeSearchTerm,
    hasError,
    filteredDialogues,
    handleTryAgain,
    orderBy,
    handleToggleOrderBy,
    handleDeleteDialogue,
    handleIdChatbot,
    getDialogue,
    dialogueOutput,
    handleLogout,
    hasWellcome,
  } = useDialogue();

  const [isVisible, setIsVisible] = useState(false);

  const hasDialogues = dialogues.length > 0;
  const isListEmpty = !hasError && (!isLoading && !hasDialogues);
  const isSearchEmpty = !hasError && (hasDialogues && filteredDialogues.length < 1);

  function isVisibleHamburguer(valor) {
    setIsVisible(valor);
  }

  return (
    <S.Container>
      <MenuHamburguer isVisible={isVisible}/>
      <Loader isLoading={isLoading} />

      {hasDialogues && (
        <InputSearch
          value={searchTerm}
          onChange={handleChangeSearchTerm}
        />
      )}

      <Header
        hasError={hasError}
        qtyOfDialogues={dialogues.length}
        qtyOfFilteredDialogues={filteredDialogues.length}
        historyUrl="/chatbot/home"
        handleIdChatbot={() => handleIdChatbot()}
        getDialogue={getDialogue}
        isVisibleHamburguer={isVisibleHamburguer}
        dialogueOutput={dialogueOutput}
        hasWellcome={hasWellcome}
        buttonsArr={dialogues}
      />

      {hasError && (<ErrorStatus onTryAgain={handleTryAgain} />)}
      {isListEmpty && <EmptyList />}
      {isSearchEmpty && <SearchNotFound searchTerm={searchTerm} />}

      {hasDialogues && (
        <>
          <DialogueList
            filteredDialogues={filteredDialogues}
            orderBy={orderBy}
            onToggleOrderBy={handleToggleOrderBy}
            onDeleteDialogue={handleDeleteDialogue}
          />

          <Modal
            danger
            isLoading={isLoadingDelete}
            visible={isDeleteModalVisible}
            title={`Tem certeza que deseja remover o dialogo "${dialogueBeingDelete?.input}"?`}
            confirmLabel="Deletar"
            onCancel={handleCloseDeleteModal}
            onConfirm={handleConfirmDeleteDialogue}
          >
            <h2>Esta ação não poderá ser desfeita</h2>
          </Modal>

          {/* <div className="logout">
            <Button onClick={handleLogout}>Sair</Button>
          </div> */}
        </>
      )}

    </S.Container>
  );
}
