class ChatbotMapper {
  toPersistence(domainChatbot) {
    return {
      id: domainChatbot?.id,
      codUser: domainChatbot?.codUser,
      nome: domainChatbot.nome,
      email: domainChatbot.email,
      telefone: domainChatbot.telefone,
      cidade: domainChatbot.cidade,
      uf: domainChatbot.uf,
      bairro: domainChatbot.bairro,
      cep: domainChatbot.cep,
      logradouro: domainChatbot.logradouro,
      numero: domainChatbot.numero,
      complemento: domainChatbot.complemento,
      createdAt: domainChatbot?.createdAt,
    };
  }

  toDomain(persistenceChatbot) {
    return {
      id: persistenceChatbot?._id,
      codUser: persistenceChatbot?.codUser,
      nome: persistenceChatbot.nome,
      email: persistenceChatbot.email,
      telefone: persistenceChatbot.telefone,
      cidade: persistenceChatbot.cidade,
      uf: persistenceChatbot.uf,
      bairro: persistenceChatbot.bairro,
      cep: persistenceChatbot.cep,
      logradouro: persistenceChatbot.logradouro,
      numero: persistenceChatbot.numero,
      complemento: persistenceChatbot.complemento,
      createdAt: persistenceChatbot?.createdAt,
      updatedAt: persistenceChatbot?.updatedAt,
    };
  }
}

export default new ChatbotMapper();
