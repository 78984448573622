class MenuDialogueMapper {
  toPersistence(domainDialogue) {
    return {
      id: domainDialogue?.id,
      codUser: domainDialogue?.codUser,
      idChatbot: domainDialogue?.idChatbot,
      codeCurrent: domainDialogue.codeCurrent,
      codeRelation: domainDialogue.codeRelation,
      codeBefore: domainDialogue.codeBefore,
      buttons: domainDialogue.buttons,
      createdAt: domainDialogue?.createdAt,
    };
  }

  toDomain(persistenceDialogue) {
    return {
      id: persistenceDialogue?._id,
      idChatbot: persistenceDialogue.idChatbot,
      nameMenu: persistenceDialogue.nameMenu.replace('-menu', ''),
      isActive: persistenceDialogue.isActive,
    };
  }
}
export default new MenuDialogueMapper();
