import React, { useState } from 'react';
import * as S from'./styles';
import LoginService from '../../services/LoginService';
import MinhaContaService from '../../services/MinhaContaService';
import Modal from '../Modal';

export default function MenuHamburguer({isVisible}) {
  const [isCancelAssinaturaModalVisible, setIsCancelAssinaturaModalVisible] = useState(false);
  
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);

  async function handleLogout() {
    await LoginService.logout();
    window.location.href = '/chatbot';
  }
  
  function handleShowCancelAssinaturaModal() {
    setIsCancelAssinaturaModalVisible(true);
  }

  function handleCloseCancelAssinaturaModal() {
    setIsCancelAssinaturaModalVisible(false);
  }
  
  async function handleConfirmCancelAssinaturaModal() {
    const cancelou = await MinhaContaService.cancelarAssinatura();
    console.log('cancelou',cancelou)
    if(cancelou.msg.includes('Sua conta foi desativada com sucesso')){
      localStorage.removeItem("termodeuso");
      localStorage.removeItem("token");
      setIsCancelAssinaturaModalVisible(false);
      window.location.href = '/chatbot';
    }
    
  }
    
    return (
      <>
          <S.MenuLabel htmlFor="navi-toggle" onClick={handleClick} isVisible={isVisible}>
                  <S.Icon clicked={click}>&nbsp;</S.Icon>
          {
          click ? <ul>
            <li><a href='/chatbot/minha-conta'>Minha Conta</a></li>
            <li onClick={handleShowCancelAssinaturaModal}>Excluir Conta</li>
            <li onClick={handleLogout}>Sair</li>
          </ul> : 
          <></>
          }
          </S.MenuLabel>
          <>
          <Modal
            danger
            visible={isCancelAssinaturaModalVisible}
            title={`Tem certeza que deseja excluir a conta? isso resultará na desativação da sua conta e depois de alguns dias a exclusão total dos dados.`}
            confirmLabel="Sim, Excluir Conta"
            onConfirm={handleConfirmCancelAssinaturaModal}
            onCancel={handleCloseCancelAssinaturaModal}
          />
        </>
      </>
    );
  }
