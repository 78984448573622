import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp, faFacebook, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import * as S from './styles';

export default function BodySite() {
  const [isFlipped, setIsFlipped] = useState(false);
  const [autoFlip, setAutoFlip] = useState(false);

  useEffect(() => {
    const checkScreenWidth = () => {
      if (window.innerWidth <= 768) {
        setAutoFlip(true);
      } else {
        setAutoFlip(false);
      }
    };

    checkScreenWidth(); // Check on initial render

    window.addEventListener('resize', checkScreenWidth);

    const handleScroll = () => {
      const elements = document.querySelectorAll('.hidden');
      elements.forEach((element) => {
        const rect = element.getBoundingClientRect();
        if (rect.top < window.innerHeight) {
          element.classList.add('fade-in');
        }
      });
    };

    window.addEventListener('scroll', handleScroll);

    // Execute a primeira verificação para elementos já visíveis na tela
    handleScroll();

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', checkScreenWidth);
    };

  }, []);

  useEffect(() => {
    if (autoFlip) {
      const interval = setInterval(() => {
        setIsFlipped(prev => !prev);
      }, 20000);

      return () => clearInterval(interval);
    }
  }, [autoFlip]);

  return (
    <>
      <S.HeaderBody><p>Abaixo estão alguns dos meus projetos próprios e de clientes</p></S.HeaderBody>
    <S.Container>
    <a href='/chatbot/' target='_blank'><S.FlipCard>
       <S.FlipCardInner isFlipped={isFlipped} autoFlip={autoFlip}>
         <S.FlipCardFront>
           <S.CardImage imageName="logo-chatbot.jpeg" />
         </S.FlipCardFront>
         <S.FlipCardBack cor="#5CB9FF">
           <h1>Chatbot</h1>
           <p>Sistema para criação de chatbots, integravél a maioria dos sistemas ou sites.</p>
           <br></br>
           <br></br>
           <p>Clique no card e seja redirecionado para a página de login/cadastro do sistema.</p>
         </S.FlipCardBack>
       </S.FlipCardInner>
     </S.FlipCard>
     </a>
     <a href='https://biggraca.com.br/' target='_blank'>
    <S.FlipCard>
       <S.FlipCardInner isFlipped={isFlipped} autoFlip={autoFlip}>
         <S.FlipCardFront>
           <S.CardImage imageName="paredetijolos.png" />
         </S.FlipCardFront>
         <S.FlipCardBack cor="#FFF984" colorText="black">
           <h1>Site da Banda Big Graça</h1>
           <p>Um dos meus primeiros projetos, um site para uma banda gospel que realiza diversas açoes sociais.</p>
           <br></br>
           <br></br>
           <p>Clique no card e seja redirecionado para o site da banda para ver como ficou.</p>
         </S.FlipCardBack>
       </S.FlipCardInner>
     </S.FlipCard>
     </a>
     <a href='/app_garcon/' target='_blank'>
    <S.FlipCard>
       <S.FlipCardInner isFlipped={isFlipped} autoFlip={autoFlip}>
         <S.FlipCardFront>
           <S.CardImage imageName="logo.svg" />
         </S.FlipCardFront>
         <S.FlipCardBack cor="#BBBBBB">
           <h1>APP do Garçon</h1>
           <p>Sistema para cozinha e aplicativo para o garçon enviar os pedidos da mesa.</p>
           <br></br>
           <br></br>
           <p>Clique no card e seja redirecionado para a página de informações do produto.</p>
         </S.FlipCardBack>
       </S.FlipCardInner>
     </S.FlipCard>
     </a>
    </S.Container>
    <S.Container>
    <a href='https://www.instagram.com/spg_personalizados/' target='_blank'>
    <S.FlipCard>
       <S.FlipCardInner isFlipped={isFlipped} autoFlip={autoFlip}>
         <S.FlipCardFront>
           <S.CardImage imageName="logo_spg.jpeg" />
         </S.FlipCardFront>
         <S.FlipCardBack cor="#F7CBCC" colorText="black">
           <h1>SPG Personalizados</h1>
           <p>Sua papelaria personalizada, solicite já um orçamento.</p>
           <br></br>
           <br></br>
           <p>Clique no card e seja redirecionado para a página do instagram e obtenha mais informações.</p>
         </S.FlipCardBack>
       </S.FlipCardInner>
     </S.FlipCard>
     </a>
    </S.Container>
    <S.ContainerSection>
    <S.Row>
      <S.Column>
        <S.SectionHead className="hidden">Quer um site para chamar de seu?</S.SectionHead><br/>
        <S.TextWhite className="hidden">
          Olá, está precisando de um <S.Strong>freelancer</S.Strong> para criação de <S.Strong>sites</S.Strong>? Sou
          <S.Strong> desenvolvedor</S.Strong> a mais de 4 anos, ainda atuo no regime CLT com Java e Javascript para grandes empresas, 
          estou apto a criar <S.Strong>sites</S.Strong> e/ou <S.Strong>sistemas web </S.Strong> 
          para todos os seguimentos e áreas profissionais, se você possui um comércio, escola, banda, serviço autônomo ou qualquer outro perfil e se interessou pelos meus serviços, peço que entre em contato pelo meio de comunicação que você preferir, os botões com links estão um pouco mais abaixo deste texto ou no começo da página.
        </S.TextWhite>
        <br /><br/>
        <h2>Entre em contato comigo!</h2>
        <br /><br />
        <S.AuthorProfile>
          <S.AuthorName className="hidden">Genildo Araujo</S.AuthorName>
          <S.AuthorTitle className="hidden">CEO e Desenvolvedor</S.AuthorTitle>
        </S.AuthorProfile>
        <S.AuthorMessage className="hidden">
          Siga meu trabalho nas principais redes sociais e fique à vontade para se comunicar por lá também.
        </S.AuthorMessage>
        <br/><br/>
        <S.SocialShare>
          <S.SocialShareItem>
            <S.SocialShareLink href="https://wa.me/5511944688144?text=Olá%20Genildo%20Araujo%20eu%20vi%20o%20seu%20site%20e%20tenho%20interesse%20em%20saber%20mais%20sobre%20os%20seus%20serviços" target="_blank">
              <FontAwesomeIcon icon={faWhatsapp} />
            </S.SocialShareLink>
          </S.SocialShareItem>
          <S.SocialShareItem>
            <S.SocialShareLink href="https://www.facebook.com/genildo.fbmdev" target="_blank">
              <FontAwesomeIcon icon={faFacebook} />
            </S.SocialShareLink>
          </S.SocialShareItem>
          <S.SocialShareItem>
            <S.SocialShareLink href="https://www.instagram.com/fbmdev/" target="_blank">
              <FontAwesomeIcon icon={faInstagram} />
            </S.SocialShareLink>
          </S.SocialShareItem>
          <S.SocialShareItem>
            <S.SocialShareLink href="https://www.linkedin.com/in/genildogon%C3%A7alvesdelimaaraujo" target="_blank">
              <FontAwesomeIcon icon={faLinkedin} />
            </S.SocialShareLink>
          </S.SocialShareItem>
        </S.SocialShare>
      </S.Column>
    </S.Row>
     </S.ContainerSection>
    </>
  );
}
