import { useState, useImperativeHandle } from 'react';
import useErrors from '../../hooks/userErrors';

import formatPhone from '../../utils/formatPhone';
import formatCep from '../../utils/formatCep';
import formatUf from '../../utils/formatUf';
import formatNumber from '../../utils/formatNumber';

export default function useChatbotForm(onSubmit, ref) {
  const [nome, setNome] = useState('');
  const [codUser, setCodUser] = useState('0');
  const [telefone, setTelefone] = useState('');
  const [cidade, setCidade] = useState('');
  const [uf, setUf] = useState('');
  const [bairro, setBairro] = useState('');
  const [cep, setCep] = useState('');
  const [logradouro, setLogradouro] = useState('');
  const [numero, setNumero] = useState('');
  const [complemento, setComplemento] = useState('');
  const [createdAt, setCreatedAt] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    errors,
    setError,
    removeError,
    getErrorMessageByFieldName,
  } = useErrors();

  const isFormValid = nome && !errors.length;

  useImperativeHandle(ref, () => ({
    setFieldsValues: (chatbot) => {
      setCodUser(chatbot.codUser ?? '0');
      setNome(chatbot.nome ?? '');
      setTelefone(formatPhone(chatbot.telefone) ?? '');
      setCidade(chatbot.cidade ?? '');
      setUf(formatUf(chatbot.uf) ?? '');
      setBairro(chatbot.bairro ?? '');
      setCep(formatCep(chatbot.cep) ?? '');
      setLogradouro(chatbot.logradouro ?? '');
      setNumero(formatNumber(chatbot.numero) ?? '');
      setCreatedAt(chatbot.createdAt ?? '');
      setComplemento(chatbot.complemento ?? '');
    },
    resetFields: () => {
      setCodUser(0);
      setNome('');
      setTelefone('');
      setCidade('');
      setUf('');
      setBairro('');
      setCep('');
      setLogradouro('');
      setNumero('');
      setCreatedAt('');
      setComplemento('');
    },
  }), []);


  function handleNomeChange(event) {
    setNome(event.target.value);

    if (!event.target.value) {
      setError({ field: 'nome', message: 'Nome é obrigatório.' });
    } else {
      removeError('nome');
    }
  }

  function handlePhoneChange(event) {
    setTelefone(formatPhone(event.target.value));
  }

  function handleCidadeChange(event) {
    setCidade(event.target.value);

    if (!event.target.value) {
      setError({ field: 'cidade', message: 'Cidade é obrigatório.' });
    } else {
      removeError('cidade');
    }
  }

  function handleUfChange(event) {
    setUf(formatUf(event.target.value));

    if (!event.target.value) {
      setError({ field: 'uf', message: 'Uf é obrigatório.' });
    } else {
      removeError('uf');
    }
  }

  function handleBairroChange(event) {
    setBairro(event.target.value);

    if (!event.target.value) {
      setError({ field: 'bairro', message: 'Bairro é obrigatório.' });
    } else {
      removeError('bairro');
    }
  }

  function handleCepChange(event) {
    setCep(formatCep(event.target.value));

    if (!event.target.value) {
      setError({ field: 'cep', message: 'CEP é obrigatório.' });
    } else {
      removeError('cep');
    }
  }

  function handleLogradouroChange(event) {
    setLogradouro(event.target.value);

    if (!event.target.value) {
      setError({ field: 'logradouro', message: 'Endereço é obrigatório.' });
    } else {
      removeError('logradouro');
    }
  }

  function handleNumeroChange(event) {
    setNumero(formatNumber(event.target.value));

    if (!event.target.value) {
      setError({ field: 'numero', message: 'Número é obrigatório.' });
    } else {
      removeError('numero');
    }
  }

  function handleComplementoChange(event) {
    setComplemento(event.target.value);
  }

  async function handleSubmit(event) {
    event.preventDefault();

    setIsSubmitting(true);

    await onSubmit({
      codUser,
      nome,
      telefone: telefone.replace('(', '').replace(')', '').replace(' ', '').replace('-', ''),
      cidade,
      uf,
      bairro,
      cep: cep.replace('-', '').replace(' ', ''),
      logradouro,
      numero,
      complemento,
      createdAt,
    });

    setIsSubmitting(false);
  }
  return {
    handleSubmit,
    getErrorMessageByFieldName,
    codUser,
    isSubmitting,
    nome,
    handleNomeChange,
    telefone,
    handlePhoneChange,
    cidade,
    handleCidadeChange,
    uf,
    handleUfChange,
    bairro,
    handleBairroChange,
    cep,
    handleCepChange,
    logradouro,
    handleLogradouroChange,
    numero,
    handleNumeroChange,
    complemento,
    handleComplementoChange,
    createdAt,
    isFormValid,
  };
}
