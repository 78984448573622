import HttpClient from './utils/HttpClient';

class RedefinirSenhaService {
  constructor() {
    this.httpClient = new HttpClient(process.env.REACT_APP_BASE_URL_API_GTW);
  }

  redefinir(redefinir) {
    const t = redefinir.dadosUser.token;
    const h = { Authorization: `Bearer ${t}` };
    return this.httpClient.post('/users/redefinir-senha', { body: redefinir, headers: h });
  }
}
export default new RedefinirSenhaService();
