import { useEffect, useState } from 'react';
import { useQuery } from '../../hooks/useQuery';
import useSafeAsyncAction from '../../hooks/useSafeAsyncAction';
import FeedbackService from '../../services/FeedbackService';
import toast from '../../utils/toast';

export default function Feedback() {
  const [totalTimeInSeconds, setTotalTimeInSeconds] = useState(5);
  const minutes = Math.floor(totalTimeInSeconds / 60);
  const seconds = totalTimeInSeconds % 60;
  const safeAsyncAction = useSafeAsyncAction();

  const query = useQuery();

  useEffect(() => {
    const status = query.get('status');
    const paymentId = query.get('payment_id');
    const merchantOrderId = query.get('merchant_order_id');
    const preferenceId = query.get('preference_id');

    const queryPayments = {
      status, paymentId, merchantOrderId, preferenceId,
    };

    function getFeedbackPage() {
      try {
        if (totalTimeInSeconds === 5) {
          safeAsyncAction(async () => {
            await FeedbackService.updatePagamentoFeedback(queryPayments);

            if (queryPayments.status === 'approved') {
              queryPayments.status = 'O pagamento foi aprovado e creditado. Em 15 segundos vamos redireciona-lo para a tela de login.';
            }
            if (queryPayments.status === 'pending') {
              queryPayments.status = 'Pagamendo pendente, o usuário não concluiu o processo de pagamento (por exemplo, ao gerar um pagamento por boleto, ele será concluído no momento em que o usuário efetuar o pagamento no local selecionado). Em 15 segundos vamos redireciona-lo para a tela de login.';
            }
            if (queryPayments.status === 'rejected') {
              queryPayments.status = 'O pagamento foi rejeitado (o usuário pode tentar pagar novamente). Caso o erro persista favor consultar a sua administradora do cartão ou instituição financeira. Em 15 segundos vamos redireciona-lo para a tela de login.';
            }
          });
        }
        if (totalTimeInSeconds === 0) {
          window.location.href = '/chatbot';
        }
        setTimeout(() => {
          setTotalTimeInSeconds(totalTimeInSeconds - 1);
        }, 1000);
      } catch {
        toast({
          type: 'danger',
          text: 'Ocorreu algum erro ao tentar buscar um Feedback',
        });
      } finally {
        console.log('feshow feedback');
      }
    }
    getFeedbackPage();
  }, [query, safeAsyncAction, totalTimeInSeconds]);

  return (
    <>
      <div>{query.get('status') === 'approved' ? 'O pagamento foi aprovado e creditado. Em 5 segundos vamos redireciona-lo para a tela de login.' : ''}</div>
      <div>{query.get('status') === 'pending' ? 'Pagamendo pendente, o usuário não concluiu o processo de pagamento(por exemplo, ao gerar um pagamento por boleto, ele será concluído no momento em que o usuário efetuar o pagamento no local selecionado). Em 15 segundos vamos redireciona-lo para a tela de login.' : ''}</div>
      <span>{minutes.toString().padStart(2, '0')}</span>
      <span>:</span>
      <span>{seconds.toString().padStart(2, '0')}</span>
    </>
  );
}
