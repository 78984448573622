import PageHeader from '../../components/PageHeader';
import DialogueForm from '../../components/DialogueForm';
import Loader from '../../components/Loader';

import useEditDialogue from './useEditDialogue';
import MenuHamburguer from '../../components/MenuHamburguer';

export default function EditDialogue() {
  const {
    isLoading,
    dialogueInput,
    dialogueFormRef,
    chatbotId,
    handleSubmit,
    handleLogout,
  } = useEditDialogue();

  return (
    <>
    <MenuHamburguer />
      <Loader isLoading={isLoading} />
      <PageHeader
        historyUrl={`/chatbot/config/${chatbotId}`}
        title={isLoading ? 'Carregando' : `Editar ${dialogueInput}`}
      />

      <p>Lembrete para campo resposta: </p>
      <p>1. chamar outro menu ex.: <b>-menu</b>Nome do Menu</p>
      <p>2. incluir links ex.: <b>linkParam</b> https://fbmstore.com.br <b>linkParam</b> </p>
      <br></br>

      <DialogueForm
        ref={dialogueFormRef}
        chatbotId={chatbotId}
        buttonLabel="Salvar alterações"
        onSubmit={handleSubmit}
      />

      {/* <div className="logout">
        <Button onClick={handleLogout}>Sair</Button>
      </div> */}
    </>
  );
}
