import PropTypes from 'prop-types';
import * as S from './styles';

export default function RedirectButton({
  idChatbot, currentUrl, targetUrl, children
}) {
  const handleRedirect = () => {
    // Construir a URL com os parâmetros
    const redirectUrl = `${targetUrl}?idChatbot=${idChatbot}&originalUrl=${encodeURIComponent(currentUrl)}`;
    
    // Abrir a nova aba com a URL
    // window.open(redirectUrl, '_blank');
    // Navegar para a nova URL na mesma aba
    window.location.href = redirectUrl;
  };
  
  return (
    <S.StyledButton
      onClick={handleRedirect}
    >
      {children}
    </S.StyledButton>
  );
}

RedirectButton.propTypes = {
  idChatbot: PropTypes.string, 
  currentUrl: PropTypes.string, 
  targetUrl: PropTypes.string,
  children: PropTypes.node.isRequired,
};

RedirectButton.defaultProps = {
  idChatbot: '', 
  currentUrl: '', 
  targetUrl: '',
};
