import styled from "styled-components";

export const MenuLabel = styled.label`
  display: ${(props) => (props.isVisible ? "none" : "block")};
  background-color: ${({ theme }) => theme.colors.primary.main};
  position: fixed;
  top: 1rem;
  left: 1rem;
  border-radius: 50%;
  height: 7rem;
  width: 7rem;
  cursor: pointer;
  z-index: 1000;
  box-shadow: 0 1rem 3rem rgba(182, 237, 200, 0.3);
  text-align: center;
  /* ${this}:hover {
    background-color: ${({ theme }) => theme.colors.primary.light};
  } */
  ul {
    margin-top: 45px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding-left: 0px;
  }
  li {
    list-style-type: none;
    color: ${({ theme }) => theme.colors.primary.dark};
    ${this}:hover {
    background-color: ${({ theme }) => theme.colors.primary.dark};
    color: #fff;
  }
  }
  a {
    text-decoration: none;
    ${this}:visited {
      color: ${({ theme }) => theme.colors.primary.dark};
    }
    ${this}:hover {
    color: #fff;
   }
  }

  @media (max-width: 768px) {
    height: 4rem;
    width: 4rem;

    ul {
    background-color: #fff;
    }
  }
`;

export const Icon = styled.span`
  position: relative;
  background-color: ${(props) => (props.clicked ? "transparent" : "white")};
  width: 3rem;
  height: 2px;
  display: inline-block;
  margin-top: 3.5rem;
  transition: all 0.3s;
  &::before,
  &::after {
    content: "";
    background-color: white;
    width: 3rem;
    height: 2px;
    display: inline-block;
    position: absolute;
    left: 0;
    transition: all 0.3s;
  }
  &::before {
    top: ${(props) => (props.clicked ? "0" : "-0.8rem")};
    transform: ${(props) => (props.clicked ? "rotate(135deg)" : "rotate(0)")};
  }
  &::after {
    top: ${(props) => (props.clicked ? "0" : "0.8rem")};
    transform: ${(props) => (props.clicked ? "rotate(-135deg)" : "rotate(0)")};
  }
  ${MenuLabel}:hover &::before {
    top: ${(props) => (props.clicked ? "0" : "-1rem")};
  }
  ${MenuLabel}:hover &::after {
    top: ${(props) => (props.clicked ? "0" : "1rem")};
  }

  @media (max-width: 768px) {
    margin-top: 2rem;
    width: 1.5rem;
    &::before,
    &::after {
      width: 1.5rem;
    }
  }
`;

export const Container = styled.div`
  position: fixed;
  right: 1rem;
  top: 1rem;
  z-index: 1;
`;
