import PageHeader from '../../components/PageHeader';
import MenuDialogueForm from '../../components/MenuDialogueForm';

import useNewMenuDialogue from './useNewMenuDialogue';
import MenuHamburguer from '../../components/MenuHamburguer';

export default function NewMenuDialogue() {
  const {
    menuDialogueFormRef,
    idChatbot,
    handleSubmit,
    handleLogout,
    wellcome,
    nameMenu,
  } = useNewMenuDialogue();

  return (
    <>
    <MenuHamburguer />
      <PageHeader
        historyUrl={wellcome ? `/chatbot/config/${idChatbot}` : `/chatbot/outros/menus/dialogue/${idChatbot}`}
        title="Menu de Dialogos"
      />
      <p>Lembrete para campo resposta: </p>
      <p>1. chamar outro menu ex.: <b>-menu</b>Nome do Menu</p>
      <p>2. incluir links ex.: <b>linkParam</b> https://fbmstore.com.br <b>linkParam</b> </p>
      <br></br>
      <MenuDialogueForm
        ref={menuDialogueFormRef}
        idbot={idChatbot}
        buttonLabel="Salvar"
        onSubmit={handleSubmit}
        wellcome={wellcome}
        historyUrl={wellcome ? `/chatbot/config/${idChatbot}` : `/chatbot/outros/menus/dialogue/${idChatbot}`}
        nameMenuParams={nameMenu}
      />
      {/* <div className="logout">
        <Button onClick={handleLogout}>Sair</Button>
      </div> */}
    </>
  );
}
