import HttpClient from './utils/HttpClient';

class ActiveAccountService {
  constructor() {
    this.httpClient = new HttpClient(process.env.REACT_APP_BASE_URL_API_GTW);
  }

  async activeAccount(token) {
    const t = token;
    const h = { Authorization: `Bearer ${t}` };
    const active = await this.httpClient.get('/signup/active', { headers: h });
    return active;
  }
}
export default new ActiveAccountService();
