import { useParams } from 'react-router-dom';
import Button from '../../components/Button';
import CheckoutService from '../../services/CheckoutService';
import toast from '../../utils/toast';

export default function Checkout() {
  const { email } = useParams();

  async function getCheckoutPage() {
    try {
      const paymentData = {
        title: 'Chatbot',
        unitPrice: 500,
        quantity: 1,
        payer: email,
      };
      const initpoint = await CheckoutService.createPayment(paymentData);
      window.location.href = `${initpoint.initPoint}`;
    } catch {
      toast({
        type: 'danger',
        text: 'Ocorreu algum erro ao tentar redirecionar para a pagina de checkout',
      });
    } finally {
      console.log('feshow getCheckoutPage');
    }
  }

  return (
    <>
      <h1>
        Olá
        {' '}
        {email}
        {' '}
        não foi identificado o pagamento da mensalidade, para acesso ao
        Dashboard do seu(s) chatbot(s)
      </h1>
      <br />
      <p>
        Será cobrado o valor de R$ 50,00 para ter acesso por um mês e toda
        vez que expirar o período de um mẽs você será redirecionado para esta
        página e solicitado um novo pagamento, pois por motivo de segurança
        não armazenamos dados de cartões ou quaisquer outro meio de pagamento em
        nossas bases, qualquer dúvida ou sugestões podem ser encaminhadas para o
        e-mail frontbackmobile@gmail.com
      </p>
      <br />
      <Button onClick={getCheckoutPage}>Pagar</Button>
    </>
  );
}
