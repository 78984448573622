import HttpClient from './utils/HttpClient';

class SignUpService {
  constructor() {
    this.httpClient = new HttpClient(process.env.REACT_APP_BASE_URL_API_GTW);
  }

  signup(signup) {
    return this.httpClient.post('/signup', { body: signup });
  }
}
export default new SignUpService();
