import styled, { keyframes, css } from 'styled-components';
import { fadeInDown, fadeInUp } from 'react-animations';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp, faFacebook, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  a {
    text-decoration: none;
  }
  a:link {
    color: black; /* cor do link não visitado */
  }

  a:visited {
      color: black; /* cor do link visitado */
  }

  a:hover {
      color: black; /* cor do link quando o mouse está sobre ele */
  }

  a:active {
      color: black; /* cor do link quando ele é clicado */
  }

  @media (max-width: 768px) {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
`;

export const HeaderBody = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
  p {
    font-size: 18px;
  }

  @media (max-width: 768px) {
   p {
    text-align: center;
   } 
  }
`;

export const CardImage = styled.div`
  background-image: url(${({ imageName }) => require(`../assets/images/${imageName}`)});
  background-size: 300px; 
  background-position: center;
  border-radius: 4px;
  background-repeat: no-repeat;
  width: 300px;
  height: 300px;
`;

export const FlipCard = styled.div`
  background-color: transparent;
  width: 300px;
  height: 300px;
  perspective: 1000px;
`;

// Define a animação de flip
export const flipAnimation = keyframes`
  0%, 25% {
    transform: rotateY(0deg);
  }
  50%, 75% {
    transform: rotateY(180deg);
  }
  100% {
    transform: rotateY(0deg);
  }
`;

export const FlipCardInner = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);

  ${FlipCard}:hover & {
    transform: ${({ isFlipped }) => (!isFlipped ? 'rotateY(180deg)' : 'rotateY(0deg)')};
  }

  transform: ${({ isFlipped }) => (isFlipped ? 'rotateY(180deg)' : 'rotateY(0deg)')};
  ${({ autoFlip }) =>
    autoFlip &&
    css`
      animation: ${flipAnimation} 20s infinite;
    `}
`;

export const FlipCardFace = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
`;

export const FlipCardFront = styled(FlipCardFace)`
  background-color: #bbb;
  color: black;
`;

export const FlipCardBack = styled(FlipCardFace)`
  background-color: ${({ cor }) => (cor)};
  color: ${({ colorText }) => (colorText ? colorText : 'white')};
  transform: rotateY(180deg);
`;

export const ContainerSection = styled.div`
  .custom-section {
      padding: 80px 0;
  }

  .custom-section-sm {
      padding: 80px 0 50px 0;
  }

  .row {
    margin-left: -10px;
    margin-right: -10px;
}

.row:before,
.row:after {
    content: " ";
    /* 1 */
    display: table;
    /* 2 */
}

.row:after {
    clear: both;
}

  /* ----------------------------
Responsive >> Media Quaries
---------------------------- */

@media(max-width:768px) {
    .navbar-brand {
        padding: 4px 15px;
    }
    .navbar-brand>img {
        width: 78%;
    }
    .header-section {
        margin: 40px 0 60px 0;
    }
    .custom-section {
        padding: 40px 0;
    }
    .custom-section-sm {
        padding: 40px 0 10px;
    }
    .hero-heading {
        font-size: 40px;
        line-height: 50px;
    }
    .feature-block {
        padding: 20px;
        max-width: 100%;
        text-align: center;
    }
    .feature-block img {
        display: block;
        margin: auto;
    }
    .download-tag {
        margin-bottom: 50px;
    }
    .mob-btn {
        width: 100%;
    }
    .discount {
        text-align: center;
        position: initial;
    }
    h1 {
        font-size: 25px;
        margin-top: 30px;
    }
    .about-heading {
        font-size: 22px;
    }
    .about-heading span {
        font-size: 24px;
    }
    .section-tag {
        margin-bottom: 30px;
    }
    .mob-btm-50 {
        margin-bottom: 50px;
    }
    .copyright1,
    .copyright2 {
        text-align: center;
    }
    .navbar-inverse .navbar-nav>li>a {
        padding: 0 15px;
    }
}
`;

const fadeInDownAnimation = keyframes`${fadeInDown}`;
const fadeInUpAnimation = keyframes`${fadeInUp}`;

export const Row = styled.div`
  text-align: center;
  margin-top: 30px;
  padding: 60px;
  background-color: #666666;

  @keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.hidden {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.6s, transform 0.6s;
}

.fade-in {
  opacity: 1;
  transform: translateY(0);
}

`;

export const Column = styled.div`
  width: 100%;
  animation: ${fadeInDownAnimation} 1000ms;
`;

export const Strong = styled.strong``;

export const AuthorProfile = styled.div`
color: #fff;
  animation: ${fadeInUpAnimation} 900ms 100ms;
`;

export const AuthorName = styled.h4`
line-height: 1.6;
  animation: ${fadeInUpAnimation} 800ms 200ms;
`;

export const AuthorTitle = styled.p`
line-height: 1.6;
  animation: ${fadeInUpAnimation} 700ms 300ms;
`;

export const AuthorMessage = styled.h3`
  color: #fff;
  margin-bottom: 5px;
  line-height: 1.6;
  animation: ${fadeInUpAnimation} 600ms 400ms;
`;

export const SocialShare = styled.ul`
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 0;
  animation: ${fadeInUpAnimation} 500ms 500ms;
`;

export const SocialShareItem = styled.li`
  margin: 0 10px;
`;

export const SocialShareLink = styled.a`
  color: inherit;
  text-decoration: none;
  font-size: 24px;
`;

export const SectionHead = styled.h1`
  color: white;
  &.hidden {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.6s, transform 0.6s;
  }
  &.fade-in {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const TextWhite = styled.p`
  color: white;
  line-height: 1.6;
  &.hidden {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.6s, transform 0.6s;
  }
  &.fade-in {
    opacity: 1;
    transform: translateY(0);
  }
`;