import { useEffect, useRef, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import APIError from '../../errors/APIError';

import MenuDialoguesService from '../../services/MenuDialoguesService';
import LoginService from '../../services/LoginService';
import toast from '../../utils/toast';

export default function useNewMenuDialogue() {
  const history = useHistory();
  const menuDialogueFormRef = useRef(null);
  const { wellcome, id } = useParams();
  const [idChatbot, setIdBot] = useState(id);
  useEffect(() => {
    setIdBot(id);
  }, [id, wellcome]);

  async function handleSubmit(menuDialogue) {
    try {
      await MenuDialoguesService.createUpadteMenuDialogue(menuDialogue);

      menuDialogueFormRef.current.resetFields();
      toast({
        type: 'success',
        text: 'Menu de Dialogos salvo com sucesso!',
      });
    } catch (error) {
      if (error instanceof APIError && error.body.msg.includes('sessão expirou')) {
        toast({
          type: 'danger',
          text: error.body.msg,
        });
        history.push("/chatbot");
      } else {
        toast({
          type: 'danger',
          text: 'Ocorreu um erro ao salvar o menu de dialogos.',
        });
      }
    }
  }

  async function handleLogout() {
    await LoginService.logout();
    window.location.href = '/chatbot';
  }

  return {
    menuDialogueFormRef,
    idChatbot,
    handleSubmit,
    handleLogout,
    wellcome,
  };
}
