import HttpClient from './utils/HttpClient';

class CheckoutService {
  constructor() {
    this.httpClient = new HttpClient(process.env.REACT_APP_BASE_URL_API_GTW);
  }

  createPayment(paymentData) {
    return this.httpClient.post('/pagamentos/checkout', { body: paymentData });
  }
}
export default new CheckoutService();
