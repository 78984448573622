import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import arrow from '../../../../assets/images/icons/arrow.svg';
import edit from '../../../../assets/images/icons/edit.svg';
import trash from '../../../../assets/images/icons/trash.svg';

import * as S from './styles';

export default function MenuDialogueList({
  filteredMenuDialogues,
  orderBy,
  onToggleOrderBy,
  onDeleteMenuDialogue,
}) {
  return (
    <>
      {filteredMenuDialogues.length > 0 && (
        <S.ListHeader orderBy={orderBy}>
          <button type="button" onClick={onToggleOrderBy}>
            <span>Nome do menu</span>
            <img src={arrow} alt="Flecha" />
          </button>
        </S.ListHeader>
      )}

      {filteredMenuDialogues.map((menuDialogue) => (
        <S.Card key={menuDialogue.nameMenu}>
          <div className="info">
            <div className="dialogue-input">
              <strong>{menuDialogue.nameMenu}</strong>
            </div>
            <span>...</span>
          </div>

          <div className="actions">
            <Link to={`/chatbot/edit/menu-dialogue/${menuDialogue.id}/${menuDialogue.nameMenu}/${menuDialogue.idChatbot}`}>
              <img src={edit} alt="Editar" />
            </Link>
            <button
              type="button"
              onClick={() => onDeleteMenuDialogue(menuDialogue)}
            >
              <img src={trash} alt="Deletar" />
            </button>
          </div>
        </S.Card>
      ))}
    </>
  );
}

MenuDialogueList.propTypes = {
  filteredMenuDialogues: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    idChatbot: PropTypes.string,
    nameMenu: PropTypes.string,
    isActive: PropTypes.bool,
  })).isRequired,
  orderBy: PropTypes.string.isRequired,
  onToggleOrderBy: PropTypes.func.isRequired,
  onDeleteMenuDialogue: PropTypes.func.isRequired,
};
