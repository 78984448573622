import styled from 'styled-components';

export const Container = styled.header`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
  width: 100%;
  color: #FFFFFF;

    background-color: #153044;
    padding: 40px 0 20px 0;

  @media (max-width: 768px) {
    p {
    text-align: center;
   } 
  }
`;
