import { useEffect, useRef, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import APIError from '../../errors/APIError';

import DialoguesService from '../../services/DialoguesService';
import LoginService from '../../services/LoginService';
import toast from '../../utils/toast';

export default function useNewDialogue() {
  const history = useHistory();
  const dialogueFormRef = useRef(null);
  const { id } = useParams();
  const [idChatbot, setIdBot] = useState(id);
  useEffect(() => {
    setIdBot(id);
  }, [id]);

  async function handleSubmit(dialogue) {
    try {
      await DialoguesService.createDialogue(dialogue);

      dialogueFormRef.current.resetFields();
      toast({
        type: 'success',
        text: 'Dialogo cadastrado com sucesso!',
      });
    } catch (error) {
      if (error instanceof APIError && error.body.msg.includes('sessão expirou')) {
        toast({
          type: 'danger',
          text: error.body.msg,
        });
        history.push("/chatbot");
      } else {
        toast({
          type: 'danger',
          text: 'Ocorreu um erro ao cadastrar o dialogo.',
        });
      }
    }
  }

  async function handleLogout() {
    await LoginService.logout();
    window.location.href = '/chatbot';
  }

  return {
    dialogueFormRef,
    idChatbot,
    handleSubmit,
    handleLogout,
  };
}
