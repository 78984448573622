import DialogueMapper from './mappers/DialogueMapper';
import HttpClient from './utils/HttpClient';

class DialoguesService {
  constructor() {
    this.httpClient = new HttpClient(process.env.REACT_APP_BASE_URL_API_GTW);
  }

  async listDialogues(id, orderBy) {
    const t = localStorage.getItem('token');
    const h = { Authorization: `Bearer ${t}` };
    const dialogues = await this.httpClient.get(`/dialogues/chatbot/${id}/${orderBy}`, { headers: h });
    return dialogues.map(DialogueMapper.toDomain);
  }

  async isMenuActive(id) {
    const t = localStorage.getItem('token');
    const h = { Authorization: `Bearer ${t}` };
    const isActive = await this.httpClient.get(`/dialogues/menu-is-active/chatbot/${id}`, { headers: h });
    return isActive;
  }

  async getDialogueByIdChatbotAndInput(id, input, flag) {
    const t = localStorage.getItem('token');
    const h = { Authorization: `Bearer ${t}` };

    const dialogue = await this.httpClient.get(`/dialogues/chatbot/${id}/question/${input.replace('?', '')}/flag/${flag}`, { headers: h });
    return dialogue.map(DialogueMapper.toDomain);
  }

  createDialogue(dialogue) {
    const body = DialogueMapper.toPersistence(dialogue);
    const t = localStorage.getItem('token');
    const h = { Authorization: `Bearer ${t}` };
    return this.httpClient.post('/dialogues', { body, headers: h });
  }

  async getDialogueById(id) {
    const t = localStorage.getItem('token');
    const h = { Authorization: `Bearer ${t}` };
    const dialogue = await this.httpClient.get(`/dialogues/${id}`, { headers: h });

    return DialogueMapper.toDomain(dialogue);
  }

  editDialogue(id, dialogue) {
    const body = DialogueMapper.toPersistence(dialogue);
    const t = localStorage.getItem('token');
    const h = { Authorization: `Bearer ${t}` };
    return this.httpClient.put(`/dialogues/${id}`, { body, headers: h });
  }

  deleteDialogue(id) {
    const t = localStorage.getItem('token');
    const h = { Authorization: `Bearer ${t}` };
    return this.httpClient.delete(`/dialogues/${id}`, { headers: h });
  }
}

export default new DialoguesService();
