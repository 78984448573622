import styled from 'styled-components';

// Estilização com styled-components
export const ChatContainer = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #f5f5f5;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px 10px 0 0;
`;

export const ChatHeader = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  background: #007bff;
  color: white;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1000;
`;

export const ChatBody = styled.div`
  flex: 1;
  padding: 100px 10px 10px 10px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
`;

export const Message = styled.div`
  margin: 5px 0;
  padding: 10px;
  border-radius: 5px;
  background: ${(props) => (props.isUser ? '#007bff' : '#e5e5ea')};
  color: ${(props) => (props.isUser ? 'white' : 'black')};
  align-self: ${(props) => (props.isUser ? 'flex-end' : 'flex-start')};
  max-width: 80%;
  word-wrap: break-word;
`;

export const ChatFooter = styled.div`
  display: flex;
  padding: 10px;
  border-top: 1px solid #ddd;
`;

export const Input = styled.input`
  flex: 1;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-right: 10px;
`;

export const Button = styled.button`
  padding: 10px 20px;
  background: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
`;