import { useState } from 'react';
import PropTypes from 'prop-types';

import useErrors from '../../hooks/userErrors';

import isEmailValid from '../../utils/isEmailValid';

import FormGroup from '../FormGroup';
import Input from '../Input';
import Button from '../Button';

import * as S from './styles';
import emailIcon from '../../assets/images/icons/icon-nova-mensagem.png';

export default function SolicitarSenhaForm({ buttonLabel, onSubmit }) {
  const [email, setEmail] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [msg, setMsg] = useState("");

  const {
    errors,
    setError,
    removeError,
    getErrorMessageByFieldName,
  } = useErrors();

  function handleEmailChange(event) {
    let valor = event.target.value;
    valor.trim();
    setEmail(valor);

    if (valor && !isEmailValid(valor)) {
      setError({ field: 'email', message: 'E-mail inválido.' });
    } else {
      removeError('email');
      console.log('errors',errors)
      if(!errors.length) {
        setIsFormValid(true);
    }
  }
}

  async function handleSubmit(event) {
    event.preventDefault();

    setIsSubmitting(true);
    
    if(!errors.length) {
        setIsFormValid(true);
    }
    
    const mensagem = await onSubmit({
      dadosUser: {
        email
      }
    });

    if (mensagem && mensagem != "") {
      setIsSubmitting(false);
      setMsg(mensagem);
    } else {
      setIsSubmitting(false);
    }
    
  }

  return (
    <S.Form onSubmit={handleSubmit} noValidate>
      <S.ToggleContainer>
      <S.Icon src={emailIcon} alt={'Email'} /><span>Informe o E-mail cadastrado</span>
      </S.ToggleContainer>

      <FormGroup error={getErrorMessageByFieldName('email')}>
        <Input
          type="text"
          error={getErrorMessageByFieldName('email')}
          placeholder="E-mail"
          value={email}
          onChange={handleEmailChange}
          disabled={isSubmitting}
        />
      </FormGroup>
      
      <S.ButtonContainer>
        <Button
          type="submit"
          disabled={!isFormValid}
          isLoading={isSubmitting}
        >
          {buttonLabel}
        </Button>
      </S.ButtonContainer>
    </S.Form>
  );
}

SolicitarSenhaForm.propTypes = {
  buttonLabel: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
};
