export default function TesteMp() {
  return (
    <>
<body>
    <form id="paymentForm">
        <div>
            <label for="cardNumber">Card Number:</label>
            <input type="text" id="cardNumber" />
        </div>
        <div>
            <label for="cardholderName">Cardholder Name:</label>
            <input type="text" id="cardholderName" />
        </div>
        <div>
            <label for="cardExpirationMonth">Expiration Month:</label>
            <input type="text" id="cardExpirationMonth" />
        </div>
        <div>
            <label for="cardExpirationYear">Expiration Year:</label>
            <input type="text" id="cardExpirationYear" />
        </div>
        <div>
            <label for="securityCode">Security Code:</label>
            <input type="text" id="securityCode" />
        </div>
        <div>
            <label for="identificationType">Identification Type:</label>
            <select id="identificationType">
                <option value="CPF">CPF</option>
                <option value="CNPJ">CNPJ</option>
            </select>
        </div>
        <div>
            <label for="identificationNumber">Identification Number:</label>
            <input type="text" id="identificationNumber" />
        </div>
        <button type="button" onclick="subscribe('basic')">Subscribe</button>
    </form>

    <script src="https://sdk.mercadopago.com/js/v2"></script>
    <script src="script.js"></script>
</body>
    </>
  );
}
