import HttpClient from './utils/HttpClient';

class UserService {
  constructor() {
    this.httpClient = new HttpClient(process.env.REACT_APP_BASE_URL_API_GTW);
  }

  getNewToken(email) {
    return this.httpClient.post('/users/renewtoken', { body: email });
  }
}
export default new UserService();
