import PropTypes from 'prop-types';
import { forwardRef } from 'react';

import FormGroup from '../FormGroup';
import Input from '../Input';
import Button from '../Button';

import * as S from './styles';
import useMenuDialogueForm from './useMenuDialogueForm';
import Loader from '../Loader';

const MenuDialogueForm = forwardRef(({
  idbot, buttonLabel, onSubmit, wellcome, historyUrl, buttonsArr,
  nameMenuParams, id,
}, ref) => {
  const {
    handleSubmit,
    getErrorMessageByFieldName,
    codUser,
    isSubmitting,
    input,
    handleInputChange,
    output,
    handleOutputChange,
    codeCurrent,
    codeRelation,
    codeBefore,
    createdAt,
    isFormValid,
    isNameMenuValid,
    idChatbot,
    addBtn,
    buttons,
    numberId,
    handleChangeButtonsInput,
    handleChangeButtonsOutput,
    handleRemoveOptionMenu,
    hasError,
    isLoading,
    handleNameMenuChange,
    nameMenu,
    idMenu,
    disable,
  } = useMenuDialogueForm(idbot, onSubmit, wellcome, historyUrl, buttonsArr,
    nameMenuParams, id, ref);

  return (
    <S.Form onSubmit={handleSubmit} noValidate>
      <Loader isLoading={isLoading} />
      {!wellcome
        && (
          <FormGroup error={getErrorMessageByFieldName('nameMenu')}>
            <Input
              type="text"
              placeholder="Digite o nome do menu *"
              error={getErrorMessageByFieldName('nameMenu')}
              value={nameMenu}
              onChange={(e) => handleNameMenuChange(e)}
              disabled={isSubmitting}
            />
          </FormGroup>
        )}
      <S.ButtonContainer>
        <Button
          type="button"
          isLoading={isSubmitting}
          onClick={(e) => addBtn(e)}
        >
          Adicionar opção
        </Button>
      </S.ButtonContainer>

      <FormGroup error={getErrorMessageByFieldName('idMenu')}>
        <Input
          type="hidden"
          error={getErrorMessageByFieldName('idMenu')}
          value={idMenu}
          disabled={isSubmitting}
        />
      </FormGroup>
      <FormGroup error={getErrorMessageByFieldName('codUser')}>
        <Input
          type="hidden"
          error={getErrorMessageByFieldName('codUser')}
          value={codUser}
          disabled={isSubmitting}
        />
      </FormGroup>

      <FormGroup error={getErrorMessageByFieldName('idChatbot')}>
        <Input
          type="hidden"
          readOnly
          error={getErrorMessageByFieldName('idChatbot')}
          value={idbot ?? idChatbot}
          disabled={isSubmitting}
        />
      </FormGroup>

      <FormGroup error={getErrorMessageByFieldName('codeCurrent')}>
        <Input
          type="hidden"
          error={getErrorMessageByFieldName('codeCurrent')}
          value={codeCurrent}
          disabled={isSubmitting}
        />
      </FormGroup>

      <FormGroup error={getErrorMessageByFieldName('codeRelation')}>
        <Input
          type="hidden"
          error={getErrorMessageByFieldName('codeRelation')}
          value={codeRelation}
          disabled={isSubmitting}
        />
      </FormGroup>

      <FormGroup error={getErrorMessageByFieldName('codeBefore')}>
        <Input
          type="hidden"
          error={getErrorMessageByFieldName('codeBefore')}
          value={codeBefore}
          disabled={isSubmitting}
        />
      </FormGroup>

      {
        buttons.map((element, index) => (
          <div key={index} id={`div${element._id ? element._id : element.id}`}>
            <FormGroup error={getErrorMessageByFieldName(`input${element._id ? element._id : element.id}`)}>
              <S.ButtonContainer>
                <Input
                  type="text"
                  error={getErrorMessageByFieldName(`input${element._id ? element._id : element.id}`)}
                  placeholder="Digite um título para opção do menu *"
                  maxLength="50"
                  value={element.input}
                  onChange={(e) => handleChangeButtonsInput(e, element)}
                  disabled={isSubmitting}
                  style={{ backgroundColor: '#ddFFFF' }}
                />
              </S.ButtonContainer>
            </FormGroup>
            <FormGroup error={getErrorMessageByFieldName(`output${element.id}`)}>
              <div style={{ display: 'flex' }}>
                <Input
                  type="text"
                  error={getErrorMessageByFieldName(`output${element.id}`)}
                  placeholder="Digite a resposta referente a opção acima"
                  maxLength="500"
                  value={element.output}
                  onChange={(e) => handleChangeButtonsOutput(e, element)}
                  disabled={isSubmitting}
                />
                <Button
                  style={{ marginLeft: 4 }}
                  onClick={() => { handleRemoveOptionMenu(index); }}
                >
                  <i
                    className="fa fa-fw fa trash"
                    aria-hidden="true"
                  />
                  Deletar
                </Button>
              </div>
            </FormGroup>
          </div>
        ))
      }

      <FormGroup error={getErrorMessageByFieldName('createdAt')}>
        <Input
          type="hidden"
          error={getErrorMessageByFieldName('createdAt')}
          value={createdAt}
          disabled={isSubmitting}
        />
      </FormGroup>

      <S.ButtonContainer>
        <Button
          type="submit"
          isLoading={isSubmitting}
        >
          {buttonLabel}
        </Button>
      </S.ButtonContainer>
    </S.Form>
  );
});

MenuDialogueForm.propTypes = {
  idbot: PropTypes.string,
  id: PropTypes.string,
  buttonLabel: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  historyUrl: PropTypes.string.isRequired,
  wellcome: PropTypes.string,
  buttonsArr: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    input: PropTypes.string.isRequired,
    output: PropTypes.string,
    codeRelation: PropTypes.string,
  })),
  nameMenuParams: PropTypes.string,
};

MenuDialogueForm.defaultProps = {
  idbot: '',
  id: '',
  wellcome: '',
  buttonsArr: [],
  nameMenuParams: '',
};

export default MenuDialogueForm;
