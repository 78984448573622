import Proptypes from 'prop-types';
import ReactPortal from '../../../../../../components/ReactPortal';
import useAnimatedUnmount from '../../../../../../hooks/useAnimatedUnmount';

import * as S from './styles';

export default function ModalChatbot({
  danger,
  isLoading,
  visible,
  title,
  children,
  cancelLabel,
  confirmLabel,
  onCancel,
}) {
  const { shouldRender, animatedElementRef: overlayRef } = useAnimatedUnmount(visible);

  if (!shouldRender) {
    return null;
  }

  return (
    <ReactPortal containerId="modalchat-root">
      <S.Overlay isLeaving={!visible} ref={overlayRef}>
        <S.Container danger={danger} isLeaving={!visible}>
          {children}
        </S.Container>
      </S.Overlay>
    </ReactPortal>
  );
}

ModalChatbot.propTypes = {
  danger: Proptypes.bool,
  visible: Proptypes.bool.isRequired,
  isLoading: Proptypes.bool,
  title: Proptypes.string.isRequired,
  children: Proptypes.node.isRequired,
  cancelLabel: Proptypes.string,
  confirmLabel: Proptypes.string,
  onCancel: Proptypes.func.isRequired,
};

ModalChatbot.defaultProps = {
  danger: false,
  isLoading: false,
  cancelLabel: 'Cancelar',
  confirmLabel: 'Confirmar',
};
