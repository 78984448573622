import PageHeader from '../../components/PageHeader';
import Loader from '../../components/Loader';

import useEditMenuDialogue from './useEditMenuDialogue';
import MenuDialogueForm from '../../components/MenuDialogueForm';
import MenuHamburguer from '../../components/MenuHamburguer';

export default function EditMenuDialogue() {
  const {
    isLoading,
    menuDialogueCodeRelation,
    menuDialogueFormRef,
    chatbotId,
    handleSubmit,
    handleLogout,
    buttonsArr,
    nameMenu,
    idMenu,
  } = useEditMenuDialogue();

  return (
    <>
    <MenuHamburguer />
      <Loader isLoading={isLoading} />
      <PageHeader
        historyUrl={`/chatbot/outros/menus/dialogue/${chatbotId}`}
        title={isLoading ? 'Carregando' : `Editar ${menuDialogueCodeRelation}`}
      />

      <p>Lembrete para campo resposta: </p>
      <p>1. chamar outro menu ex.: <b>-menu</b>Nome do Menu</p>
      <p>2. incluir links ex.: <b>linkParam</b> https://fbmstore.com.br <b>linkParam</b> </p>
      <br></br>

      <MenuDialogueForm
        idbot={chatbotId}
        ref={menuDialogueFormRef}
        buttonLabel="Salvar"
        onSubmit={handleSubmit}
        wellcome=""
        historyUrl={`/chatbot/outros/menus/dialogue/${chatbotId}`}
        buttonsArr={buttonsArr}
        nameMenuParams={nameMenu}
        id={idMenu}
      />

      {/* <div className="logout">
        <Button onClick={handleLogout}>Sair</Button>
      </div> */}
    </>
  );
}
