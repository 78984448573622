import HttpClient from './utils/HttpClient';

class SolicitarSenhaService {
  constructor() {
    this.httpClient = new HttpClient(process.env.REACT_APP_BASE_URL_API_GTW);
  }

  solicitar(solicitar) {
    return this.httpClient.post('/solicitar-senha', { body: solicitar });
  }
}
export default new SolicitarSenhaService();
