
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import Loader from '../../components/Loader';
import APIError from '../../errors/APIError';
import toast from '../../utils/toast';
import DialoguesService from '../../services/DialoguesService';
import * as S from './styles';

const ChatbotPage = () => {
  const [originalUrl, setOriginalUrl] = useState('');
  const location = useLocation();
  // const [hasWellcome] = useGlobalState('hasWellcome');
  const [hasWellcome, setHasWellcome] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  
  // const [messages, setMessages] = useState([]);
  // const [input, setInput] = useState('');
  const chatBodyRef = useRef(null);

  const params = new URLSearchParams(location.search);
  const url = params.get('originalUrl');
  const idChatbot = params.get('idChatbot');

  // useEffect(() => {
  //   if (chatBodyRef.current) {
  //     chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
  //   }
  // }, [messages]);
  
  useEffect(async () => {
    // Obter parâmetros da URL
  
  if (url) {
    setOriginalUrl(decodeURIComponent(url));
  }
  
  setIsLoading(true);

    const menuWellcomeActive = await DialoguesService.isMenuActive(idChatbot);

    setHasWellcome(menuWellcomeActive);
  
    if (menuWellcomeActive.length > 0) {
      setTimeout(() => {
        addChildMenuWellcome(menuWellcomeActive);
      }, 2000);
    } else {
      setIsLoading(false);
    }
  }, []);

  useEffect(async () => {
    const handleResize = () => {
      if (chatBodyRef.current) {
        chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
      }
    };

    window.addEventListener('resize', handleResize);
  return () => {
    window.removeEventListener('resize', handleResize);
  };
}, [location]);

async function addChildMenuWellcome(arrayWellcome) {
  const divOtherMenu = document.createElement('div');
  divOtherMenu.setAttribute('class', 'menu-wellcome');
  const ul = document.createElement('ul');
  await arrayWellcome.map((el) => {
    const li = document.createElement('li');
    li.textContent = el.input;
    li.addEventListener('click', () => addChildByQuestion(el.input, '-menu'));
    ul.appendChild(li);
  });
  const msg = document.getElementById('msg');
  divOtherMenu.appendChild(ul);
  msg.appendChild(divOtherMenu);

  document.getElementById('input').value = '';
  // document.getElementById('input').focus();
  setIsLoading(false);
}

const handleGoBack = () => {
  if (originalUrl) {
    window.location.href = originalUrl;
  }
};

  // const handleSendMessage = () => {
  //   if (input.trim()) {
  //     setMessages((prevMessages) => [...prevMessages, { text: input, isUser: true }]);
  //     setInput('');

  //     // Simulando resposta do chatbot
  //     setTimeout(() => {
  //       setMessages((prevMessages) => [...prevMessages, { text: 'Esta é uma resposta automática do chatbot.', isUser: false }]);
  //     }, 1000);
  //   }
  // };

async function getDialogue(idChatbot, input, flag) {
  try {
    setIsLoading(true);

    if (input === '') {
      return 'Para poder te ajudar preciso que descreva a sua dúvida, sem texto não consigo adivinhar';
    }

    const dialogue = await DialoguesService
      .getDialogueByIdChatbotAndInput(idChatbot, input, flag);

    return dialogue;
  } catch (e) {
    console.log('errrorrro', e);
    return [];
  } finally {
    setIsLoading(false);
  }
}

async function addChildByQuestion(question, flag) {
  const msg = document.getElementById('msg');

  const divOtherMenu = document.createElement('div');
  divOtherMenu.setAttribute('class', 'menu-wellcome');

  try {
    const output = await getDialogue(idChatbot, question, flag);

    if (output.length === 1 && output[0].output.includes('-menu')) {
      output[0].output.replace('-menu', '');
      const end = document.getElementById('end');
      if (end) {
        msg.removeChild(end);
      }

      const divQuestion = document.createElement('div');
      divQuestion.setAttribute('class', 'talk-bubble tri-right right-top');

      const divTalkTextQuestion = document.createElement('div');
      divTalkTextQuestion.setAttribute('class', 'talktext-input');

      const paragrafoQuestion = document.createElement('p');
      paragrafoQuestion.textContent = question;

      msg.appendChild(divQuestion);
      divQuestion.appendChild(divTalkTextQuestion);
      divTalkTextQuestion.appendChild(paragrafoQuestion);

      const ul = document.createElement('ul');
      await output.map((el) => {
        const li = document.createElement('li');
        li.textContent = el.input;
        li.addEventListener('click', () => addChildByQuestion(el.input, '-menu'));
        ul.appendChild(li);
      });

      const linkEnd = document.createElement('a');
      linkEnd.setAttribute('href', '#');
      linkEnd.setAttribute('id', 'end');

      divOtherMenu.appendChild(ul);
      msg.appendChild(divOtherMenu);
      msg.appendChild(linkEnd);
    } else if (output.length > 1) {
      const end = document.getElementById('end');
      if (end) {
        msg.removeChild(end);
      }

      const divQuestion = document.createElement('div');
      divQuestion.setAttribute('class', 'talk-bubble tri-right right-top');

      const divTalkTextQuestion = document.createElement('div');
      divTalkTextQuestion.setAttribute('class', 'talktext-input');

      const paragrafoQuestion = document.createElement('p');
      paragrafoQuestion.textContent = question;

      msg.appendChild(divQuestion);
      divQuestion.appendChild(divTalkTextQuestion);
      divTalkTextQuestion.appendChild(paragrafoQuestion);

      const ul = document.createElement('ul');
      await output.map((el) => {
        const li = document.createElement('li');
        li.textContent = el.input;
        li.addEventListener('click', () => addChildByQuestion(el.input, '-menu'));
        ul.appendChild(li);
      });

      const linkEnd = document.createElement('a');
      linkEnd.setAttribute('href', '#');
      linkEnd.setAttribute('id', 'end');

      divOtherMenu.appendChild(ul);
      msg.appendChild(divOtherMenu);
      msg.appendChild(linkEnd);
    } else {
      const end = document.getElementById('end');
      if (end) {
        msg.removeChild(end);
      }
      const divQuestion = document.createElement('div');
      divQuestion.setAttribute('class', 'talk-bubble tri-right right-top');

      const divTalkTextQuestion = document.createElement('div');
      divTalkTextQuestion.setAttribute('class', 'talktext-input');

      const paragrafoQuestion = document.createElement('p');
      paragrafoQuestion.textContent = question;

      msg.appendChild(divQuestion);
      divQuestion.appendChild(divTalkTextQuestion);
      divTalkTextQuestion.appendChild(paragrafoQuestion);

      const divResponse = document.createElement('div');
      divResponse.setAttribute('class', 'talk-bubble tri-right left-top');

      const divTalkTextResponse = document.createElement('div');
      divTalkTextResponse.setAttribute('class', 'talktext-output');

      const paragrafoResponse = document.createElement('p');
      paragrafoResponse.textContent = output[0] ? output[0].output : 'Erro inesperado, caso continue favor entrar em contato com o administrador pelo e-mail: frontbackmobile@gmail.com';

      msg.appendChild(divResponse);
      divResponse.appendChild(divTalkTextResponse);
      divTalkTextResponse.appendChild(paragrafoResponse);

      const linkEnd = document.createElement('a');
      linkEnd.setAttribute('href', '#');
      linkEnd.setAttribute('id', 'end');

      msg.appendChild(linkEnd);
    }

    document.getElementById('input').value = '';
    window.location.href = '#end';
    // document.getElementById('input').focus();
  } catch (error) {
    if (error instanceof APIError && error.body.msg.includes('sessão expirou')) {
      toast({
        type: 'danger',
        text: error.body.msg,
      });
      history.push("/chatbot");
    } else {
      console.log('error:  ', error)
      toast({
        type: 'danger',
        text: 'Erro inesperado, contate o administrador do sistema.',
      });
    }
  }
}

function send(e) {
  console.log('eu')
  if (e.keyCode === 13) {
    const Input = document.getElementById('input');
  const question = Input.value.toString().trim();
  if(question == ""){
    alert('Digite algo para poder pesquisar!');
  } else {
  addChildByQuestion(question, 'input');
  return false;
  }
  }
}

function sendByBtn() {
  const Input = document.getElementById('input');
  const question = Input.value.toString().trim();
  if(question == ""){
    alert('Digite algo para poder pesquisar!');
  } else {
  addChildByQuestion(question, 'input');
  return false;
  }
}

  return (
    <>
    <Loader isLoading={isLoading} />
    <S.ChatContainer>
      <S.ChatHeader>
        ChatBot - FbmStore
        <div className="div-myButton">
          <span className="myButton-mobile" onClick={handleGoBack} />
        </div>
      </S.ChatHeader>
      <S.ChatBody ref={chatBodyRef}>
        <div id="msg">
            <div className="talk-bubble tri-right left-top">
              <div className="talktext-output">
                <p className='p-top'>
                  {hasWellcome.length > 0 ? 'Escolha nossas opções abaixo!' : 'Olá! Em que posso ajudar?'}
                </p>
              </div>
            </div>
          </div>
      </S.ChatBody>
      <S.ChatFooter>
        <S.Input
          type="text"
          id="input"
          // value={input}
          // onChange={(e) => setInput(e.target.value)}
          // onKeyDown={(e) => e.key === 'Enter' && handleSendMessage()}
          onKeyDown={(e) => send(e)}
          placeholder="Digite sua mensagem..."
        />
        <S.Button onClick={sendByBtn}>Enviar</S.Button>
      </S.ChatFooter>
    </S.ChatContainer>
  </>
  );
};

export default ChatbotPage;

ChatbotPage.propTypes = {
  hasWellcome: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    input: PropTypes.string.isRequired,
    output: PropTypes.string,
    codeRelation: PropTypes.string,
  })),
};

ChatbotPage.defaultProps = {
  hasWellcome: [],
};
