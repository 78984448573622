import PageHeader from '../../components/PageHeader';
import ChatbotForm from '../../components/ChatbotForm';

import useNewChatbot from './useNewChatbot';
import MenuHamburguer from '../../components/MenuHamburguer';

export default function NewChatbot() {
  const {
    chatbotFormRef,
    handleSubmit,
    handleLogout,
  } = useNewChatbot();
  return (
    <>
    <MenuHamburguer />
      <PageHeader
        historyUrl="/chatbot/home"
        title="Novo Chatbot"
      />

      <ChatbotForm
        ref={chatbotFormRef}
        buttonLabel="Cadastrar"
        onSubmit={handleSubmit}
      />

    </>
  );
}
