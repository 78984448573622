import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';
import useErrors from '../../hooks/userErrors';

import isEmailValid from '../../utils/isEmailValid';

import FormGroup from '../FormGroup';
import Input from '../Input';
import Button from '../Button';

import * as S from './styles';

export default function SignUpForm({ buttonLabel, onSubmit }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    errors,
    setError,
    removeError,
    getErrorMessageByFieldName,
  } = useErrors();

  // const isFormValid = email && cpfOrCnpj 
  // && password && passwordConfirm && firstName && lastName 
  // && cardNumber && expirationCardHolder && securityCode && cardHolderName 
  // && cardHolderCpfOrCnpj && !errors.length;
  const isFormValid = email && password && passwordConfirm && !errors.length;

  useEffect(() => {
    async function loadMPCLoad() {
      (function() {
      function $MPC_load() {
        window.$MPC_loaded !== true && (function() {
        var s = document.createElement("script");
        s.type = "text/javascript";
        s.async = true;
        s.src = document.location.protocol + "//secure.mlstatic.com/mptools/render.js";
        var x = document.getElementsByTagName('script')[0];
        x.parentNode.insertBefore(s, x);
        window.$MPC_loaded = true;
     })();
  }
   window.$MPC_loaded !== true ? (window.attachEvent ? window.attachEvent('onload', $MPC_load) : window.addEventListener('load', $MPC_load, false)) : null;
   })();
  /*
        // to receive event with message when closing modal from congrants back to site
        function $MPC_message(event) {
          // onclose modal ->CALLBACK FUNCTION
         // !!!!!!!!FUNCTION_CALLBACK HERE Received message: {event.data} preapproval_id !!!!!!!!
        }
        window.$MPC_loaded !== true ? (window.addEventListener("message", $MPC_message)) : null; 
        */
      }

      loadMPCLoad();
  }, []);

  function handleEmailChange(event) {
    let valor = event.target.value;
    valor.trim();
    setEmail(valor);

    if (valor && !isEmailValid(valor)) {
      setError({ field: 'email', message: 'E-mail inválido.' });
    } else {
      removeError('email');
    }
  }

  // function handleFirstNameChange(event) {
  //   let name = event.target.value.trim();
  //   setFirstName(name);

  //   if (name && name.length == 0) {
  //     setError({ field: 'firstName', message: 'Primeiro nome inválido.' });
  //   } else {
  //     removeError('firstName');
  //   }
  // }

  // function handleLastNameChange(event) {
  //   let lastName = event.target.value;
  //   setLastName(lastName);

  //   if (lastName && lastName.length == 0) {
  //     setError({ field: 'lastName', message: 'Sobrenome inválido.' });
  //   } else {
  //     removeError('lastName');
  //   }
  // }

  // function handleCpfOrCnpjChange(event) {
  //   let valor = event.target.value.length > 14 ? formatCnpj(event.target.value) : formatCpf(event.target.value);

  //   setCpfOrCnpj(valor);

  //   if (valor && !isCpfOrCnpjValid(valor)) {
  //     setError({ field: 'cpfOrCnpj', message: 'Cpf ou Cnpj inválido.' });
  //   } else {
  //     removeError('cpfOrCnpj');
  //   }
  // }

  function handlePasswordChange(event) {
    let valor = event.target.value;
    valor.trim();
    setPassword(valor);

    if (!valor) {
      setError({ field: 'password', message: 'Senha é obrigatório.' });
    } else {
      removeError('password');
    }
  }

  function handlePasswordConfirmChange(event) {
    let valor = event.target.value;
    valor.trim();
    setPasswordConfirm(valor);

    if (!valor) {
      setError({ field: 'passwordConfirm', message: 'Confirmação de senha é obrigatório.' });
    } else {
      removeError('passwordConfirm');
    }
  }

  // function handleCardNumberChange(event) {
  //   let valor = event.target.value;

  //   var imageName = '';
  //   var cardNumber = valor.replace(/\s/g, '');
  //       if (/^4/.test(cardNumber)) {
  //           imageName = 'visa';
  //       } else if (/^5[1-5]/.test(cardNumber)) {
  //           imageName = 'master';
  //       } else if (/^3[47]/.test(cardNumber)) {
  //           imageName = 'amex';
  //       } else if (/^6(?:011|5[0-9]{2})/.test(cardNumber)) {
  //           imageName = 'discover';
  //       } else if (/^(?:2131|1800|35\d{3})/.test(cardNumber)) {
  //           imageName = 'jcb';
  //       } else if (/^3(?:0[0-5]|[68])/.test(cardNumber)) {
  //           imageName = 'diners';
  //       } else if (/^(?:5[0678]|6304|6390|67\d{2})/.test(cardNumber)) {
  //           imageName = 'master';
  //       } else {
  //           imageName = 'credit-debit-card';
  //       }
    
  //       if (imageName !== '') {
  //           setImageNameProps(`${imageName}`);
  //       } 

  //   let novoValor = formatCardNumber(valor, imageName);
  //   setCardNumber(novoValor);

  //   if (!valor) {
  //     setError({ field: 'cardNumber', message: 'Número do cartão é obrigatório.' });
  //   } else {
  //     removeError('cardNumber');
  //   }
  // }
  
  // function handleExpirationCardHolderChange(event) {
  //   let valor = formatExpirationCardHolder(event.target.value);
  //   setExpirationCardHolder(valor);

  //   if (!event.target.value) {
  //     setError({ field: 'expirationCardHolder', message: 'Data de expiração do cartão é obrigatório.' });
  //   } else {
  //     removeError('expirationCardHolder');
  //   }
  // }
  
  // function handleSecurityCodeChange(event) {
  //   setSecurityCode(event.target.value);

  //   if (!event.target.value) {
  //     setError({ field: 'securityCode', message: 'CVV do cartão é obrigatório.' });
  //   } else {
  //     removeError('securityCode');
  //   }
  // }
  
  // function handleCardHolderNameChange(event) {
  //   setCardHolderName(event.target.value);

  //   if (!event.target.value) {
  //     setError({ field: 'cardHolderName', message: 'Nome do proprietário do cartão é obrigatório.' });
  //   } else {
  //     removeError('cardHolderName');
  //   }
  // }
  
  // function handleCardHolderCpfOrCnpjChange(event) {
  //   let valor = event.target.value.length > 14 ? formatCnpj(event.target.value) : formatCpf(event.target.value);

  //   setCardHolderCpfOrCnpj(valor);

  //   if (valor && !isCpfOrCnpjValid(valor)) {
  //     setError({ field: 'cardHolderCpfOrCnpj', message: 'Número do CPF ou CNPJ do cartão é obrigatório.' });
  //   } else {
  //     removeError('cardHolderCpfOrCnpj');
  //   }
  // }

  async function handleSubmit(event) {
    event.preventDefault();

    setIsSubmitting(true);

    await onSubmit({
      dadosUser: {
        email, 
        password, 
        passwordConfirm, 
      }
    });

    setIsSubmitting(false);
  }

  return (
    <S.Form onSubmit={handleSubmit} noValidate>
      <FormGroup error={getErrorMessageByFieldName('email')}>
        <Input
          type="text"
          error={getErrorMessageByFieldName('email')}
          placeholder="E-mail *"
          value={email}
          onChange={handleEmailChange}
          disabled={isSubmitting}
        />
      </FormGroup>
      
      {/* <FormGroup error={getErrorMessageByFieldName('firstName')}>
        <Input
          type="text"
          error={getErrorMessageByFieldName('firstName')}
          placeholder="Primeiro nome *"
          value={firstName}
          onChange={handleFirstNameChange}
          disabled={isSubmitting}
        />
      </FormGroup> */}
      
      {/* <FormGroup error={getErrorMessageByFieldName('lastName')}>
        <Input
          type="text"
          error={getErrorMessageByFieldName('lastName')}
          placeholder="Sobrenome *"
          value={lastName}
          onChange={handleLastNameChange}
          disabled={isSubmitting}
        />
      </FormGroup> */}
      
      {/* <FormGroup error={getErrorMessageByFieldName('cpfOrCnpj')}>
        <Input
          type="text"
          error={getErrorMessageByFieldName('cpfOrCnpj')}
          placeholder="Cpf ou Cnpj do usuário do sistema *"
          value={cpfOrCnpj}
          maxLength={18}
          onChange={handleCpfOrCnpjChange}
          onBlur={handleCpfOrCnpjChange}
          onPaste={handleCpfOrCnpjChange}
          disabled={isSubmitting}
        />
      </FormGroup> */}

      <FormGroup error={getErrorMessageByFieldName('password')}>
        <Input
          type="password"
          autoComplete="new-password"
          error={getErrorMessageByFieldName('password')}
          placeholder="Senha *"
          value={password}
          onChange={handlePasswordChange}
          disabled={isSubmitting}
        />
      </FormGroup>

      <FormGroup error={getErrorMessageByFieldName('passwordConfirm')}>
        <Input
          type="password"
          autoComplete="new-password"
          error={getErrorMessageByFieldName('passwordConfirm')}
          placeholder="Confirmar Senha *"
          value={passwordConfirm}
          onChange={handlePasswordConfirmChange}
          disabled={isSubmitting}
        />
      </FormGroup>
      
      <S.ButtonContainer>
        <Button
          type="submit"
          disabled={!isFormValid}
          isLoading={isSubmitting}
          onClick={handleSubmit}
        >
          {buttonLabel}
        </Button>
        <Link to="/chatbot">Já tenho conta</Link>
      </S.ButtonContainer>
    </S.Form>
  );
}

SignUpForm.propTypes = {
  buttonLabel: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
};
