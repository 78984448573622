import HttpClient from './utils/HttpClient';

class LoginService {
  constructor() {
    this.httpClient = new HttpClient(process.env.REACT_APP_BASE_URL_API_GTW);
  }

  login(login) {
    return this.httpClient.post('/login', { body: login });
  }

  logout() {
    const t = localStorage.getItem('token');
    const h = { Authorization: `Bearer ${t}` };
    return this.httpClient.post('/logout', { headers: h });
  }
}
export default new LoginService();
