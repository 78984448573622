import Proptypes from 'prop-types';
import { Link } from 'react-router-dom';

import { Container } from './styles';

export default function Header({
  hasError,
  qtyOfChatbots,
  qtyOfFilteredChatbots,
}) {
  const alignment = hasError
    ? 'flex-end'
    : (
      qtyOfChatbots > 0
        ? 'space-between'
        : 'center'
    );

  return (
    <Container
      justifyContent={alignment}
    >
      {(!hasError && qtyOfChatbots > 0) && (
        <strong>
          {qtyOfFilteredChatbots}
          {' '}
          {qtyOfFilteredChatbots === 1 ? 'chatbot' : 'chatbots'}

        </strong>
      )}
      <Link to="/chatbot/new">Novo Chatbot</Link>
    </Container>
  );
}

Header.propTypes = {
  hasError: Proptypes.bool.isRequired,
  qtyOfChatbots: Proptypes.number.isRequired,
  qtyOfFilteredChatbots: Proptypes.number.isRequired,
};
