import PropTypes from 'prop-types';
import Spinner from '../Spinner';

import * as S from './styles';

export default function Button({
  id,
  type,
  contentEditable,
  disabled,
  isLoading,
  children,
  danger,
  onClick,
  suppressContentEditableWarning,
}) {
  return (
    <S.StyledButton
      id={id}
      type={type}
      contentEditable={contentEditable}
      disabled={disabled || isLoading}
      danger={danger}
      onClick={onClick}
      suppressContentEditableWarning={suppressContentEditableWarning}
    >
      {!isLoading && children}
      {isLoading && <Spinner size={16} />}

    </S.StyledButton>
  );
}

Button.propTypes = {
  id: PropTypes.number,
  type: PropTypes.string,
  contentEditable: PropTypes.bool,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  children: PropTypes.node.isRequired,
  danger: PropTypes.bool,
  onClick: PropTypes.func,
  suppressContentEditableWarning: PropTypes.bool,
};

Button.defaultProps = {
  id: 0,
  type: 'button',
  contentEditable: false,
  disabled: false,
  isLoading: false,
  danger: false,
  onClick: undefined,
  suppressContentEditableWarning: true,
};
