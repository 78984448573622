import * as S from './styles';

export default function HeaderSite() {
  return (
    <S.Container>
      <a href='/'><S.CardImage imageName="logo-fbmstore.jpeg" size="55px"/></a>
      <h1>Criação de Sites, Sistemas Web e Aplicativos</h1>
      <S.Contato>
        <S.Telefone>
          <p>Telefone: (11) 9 4468 8144</p><a href='https://wa.me/5511944688144?text=Olá gostaria de mais informações sobre os seus serviços' target='_blank'><S.CardImage imageName="whatsapp.png" size="35px" shouldShake/></a>
        </S.Telefone>
        <S.Email>
          <p>E-mail: frontbackmobile@gmail.com</p>
        </S.Email>
      </S.Contato>
    </S.Container>
  );
}
