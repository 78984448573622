import HttpClient from './utils/HttpClient';

class FeedbackService {
  constructor() {
    this.httpClient = new HttpClient(process.env.REACT_APP_BASE_URL_API_GTW);
  }

  updatePagamentoFeedback(query) {
    return this.httpClient.post('/pagamentos/feedback', { body: query });
  }
}
export default new FeedbackService();
