import {
  useEffect, useState, useMemo, useCallback,
} from 'react';
import { useHistory } from 'react-router-dom';
import toast from '../../utils/toast';

import ChatbotsService from '../../services/ChatbotsService';
import LoginService from '../../services/LoginService';

export default function useHome() {
  const [chatbots, setChatbots] = useState([]);
  const [orderBy, setOrderBy] = useState('asc');
  const [searchTerm, setSearchTerm] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [chatbotBeingDelete, setChatbotBeingDelete] = useState(null);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const [isTermoModalVisible, setIsTermoModalVisible] = useState(false);
  const history = useHistory();

  const filteredChatbots = useMemo(() => chatbots.filter((chatbot) => (
    chatbot.nome.toLowerCase().includes(searchTerm.toLowerCase())
  )), [chatbots, searchTerm]);

  const loadChatbots = useCallback(async () => {
    try {
      setIsLoading(true);

      const chatbotsList = await ChatbotsService.listChatbots(orderBy);

      setHasError(false);
      setChatbots(chatbotsList);
      const termo = localStorage.getItem("termodeuso");
      if(!termo) {
        setIsTermoModalVisible(true);
      }
    } catch {
      setHasError(true);
      setChatbots([]);
      history.push('/chatbot');
    } finally {
      setIsLoading(false);
    }
  }, [orderBy, history]);

  useEffect(() => {
    loadChatbots();
  }, [loadChatbots]);

  function handleToggleOrderBy() {
    setOrderBy(
      (prevState) => (prevState === 'asc' ? 'desc' : 'asc'),
    );
  }

  function handleChangeSearchTerm(event) {
    setSearchTerm(event.target.value);
  }

  function handleTryAgain() {
    loadChatbots();
  }

  function handleDeleteChatbot(chatbot) {
    setChatbotBeingDelete(chatbot);
    setIsDeleteModalVisible(true);
  }

  async function handleLogout() {
    await LoginService.logout();
    window.location.href = '/chatbot';
  }

  function handleCloseDeleteModal() {
    setIsDeleteModalVisible(false);
  }
  
  function handleCloseTermoModal() {
    setIsTermoModalVisible(false);
  }
  
  function handleConfirmTermoModal() {
    localStorage.setItem("termodeuso", true);
    setIsTermoModalVisible(false);
  }

  async function handleConfirmDeleteChatbot() {
    try {
      setIsLoadingDelete(true);

      const result = await ChatbotsService.deleteChatbot(chatbotBeingDelete.id);
      if(result.msg.includes('sessão expirou')){
        toast({
          type: 'danger',
          text: 'Provavelmente sua sessão expirou, por favor realize novo login',
        });
        history.push('/chatbot');
      }
      setChatbots((prevState) => prevState.filter(
        (chatbot) => chatbot.id !== chatbotBeingDelete.id,
      ));

      handleCloseDeleteModal();

      toast({
        type: 'success',
        text: 'chatbot excluído com sucesso',
      });
    } catch {
      toast({
        type: 'danger',
        text: 'Ocorreu um erro ao tentar excluir o chatbot',
      });
    } finally {
      setIsLoadingDelete(false);
    }
  }

  return {
    isLoading,
    isLoadingDelete,
    isDeleteModalVisible,
    handleCloseDeleteModal,
    chatbotBeingDelete,
    handleConfirmDeleteChatbot,
    chatbots,
    searchTerm,
    handleChangeSearchTerm,
    hasError,
    filteredChatbots,
    handleTryAgain,
    orderBy,
    handleToggleOrderBy,
    handleDeleteChatbot,
    handleLogout,
    isTermoModalVisible,
    handleCloseTermoModal,
    handleConfirmTermoModal
  };
}
