import PropTypes from 'prop-types';
import { forwardRef } from 'react';

import FormGroup from '../FormGroup';
import Input from '../Input';
import Button from '../Button';

import * as S from './styles';
import useDialogueForm from './useDialogueForm';

const DialogueForm = forwardRef(({ chatbotId, buttonLabel, onSubmit }, ref) => {
  const {
    handleSubmit,
    getErrorMessageByFieldName,
    codUser,
    isSubmitting,
    input,
    handleInputChange,
    output,
    handleOutputChange,
    codeCurrent,
    codeRelation,
    codeBefore,
    createdAt,
    isFormValid,
  } = useDialogueForm(chatbotId, onSubmit, ref);

  return (
    <S.Form onSubmit={handleSubmit} noValidate>
      <FormGroup error={getErrorMessageByFieldName('codUser')}>
        <Input
          type="hidden"
          error={getErrorMessageByFieldName('codUser')}
          value={codUser}
          disabled={isSubmitting}
        />
      </FormGroup>

      <FormGroup error={getErrorMessageByFieldName('idChatbot')}>
        <Input
          type="hidden"
          readOnly
          error={getErrorMessageByFieldName('idChatbot')}
          value={chatbotId}
          disabled={isSubmitting}
        />
      </FormGroup>

      <FormGroup error={getErrorMessageByFieldName('codeCurrent')}>
        <Input
          type="hidden"
          error={getErrorMessageByFieldName('codeCurrent')}
          value={codeCurrent}
          disabled={isSubmitting}
        />
      </FormGroup>

      <FormGroup error={getErrorMessageByFieldName('codeRelation')}>
        <Input
          type="hidden"
          error={getErrorMessageByFieldName('codeRelation')}
          value={codeRelation}
          disabled={isSubmitting}
        />
      </FormGroup>

      <FormGroup error={getErrorMessageByFieldName('codeBefore')}>
        <Input
          type="hidden"
          error={getErrorMessageByFieldName('codeBefore')}
          value={codeBefore}
          disabled={isSubmitting}
        />
      </FormGroup>

      <FormGroup error={getErrorMessageByFieldName('input')}>
        <Input
          error={getErrorMessageByFieldName('input')}
          maxLength="50"
          placeholder="Pergunta *"
          value={input}
          onChange={handleInputChange}
          disabled={isSubmitting}
        />
      </FormGroup>

      <FormGroup error={getErrorMessageByFieldName('output')}>
        <Input
          error={getErrorMessageByFieldName('output')}
          maxLength="500"
          placeholder="Resposta *"
          value={output}
          onChange={handleOutputChange}
          disabled={isSubmitting}
        />
      </FormGroup>

      <FormGroup error={getErrorMessageByFieldName('createdAt')}>
        <Input
          type="hidden"
          error={getErrorMessageByFieldName('createdAt')}
          value={createdAt}
          disabled={isSubmitting}
        />
      </FormGroup>

      <S.ButtonContainer>
        <Button
          type="submit"
          disabled={!isFormValid}
          isLoading={isSubmitting}
        >
          {buttonLabel}
        </Button>
      </S.ButtonContainer>
    </S.Form>
  );
});

DialogueForm.propTypes = {
  chatbotId: PropTypes.string.isRequired,
  buttonLabel: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default DialogueForm;
