import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  margin: 0 auto;

  @media (max-width: 768px) {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
`;

export const Separator = styled.div`
  width: 90%; /* Ajuste esta largura conforme necessário */
  height: 1px;
  background-color: #000080; /* Cor da linha */
  margin: 0 auto; /* Centraliza a linha */
  margin-bottom: 10px;
`;