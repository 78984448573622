import { useParams, useHistory } from 'react-router-dom';
import {
  useEffect, useState, useMemo, useCallback,
} from 'react';
import toast from '../../utils/toast';

import DialoguesService from '../../services/DialoguesService';
import LoginService from '../../services/LoginService';
import { setGlobalState, useGlobalState } from '../../assets/state';
import APIError from '../../errors/APIError';

export default function useDialogue() {
  const { id } = useParams();
  const history = useHistory();
  const [dialogues, setDialogues] = useState([]);
  const [orderBy, setOrderBy] = useState('asc');
  const [searchTerm, setSearchTerm] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [dialogueBeingDelete, setDialogueBeingDelete] = useState(null);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const [hasWellcome] = useGlobalState('hasWellcome');

  const filteredDialogues = useMemo(() => dialogues.filter((dialogue) => (
    (dialogue.input.toLowerCase().includes(searchTerm.toLowerCase())
      || dialogue.output.toLowerCase().includes(searchTerm.toLowerCase()))
  )), [dialogues, searchTerm]);

  const loadDialogues = useCallback(async () => {
    try {
      setIsLoading(true);

      const menuWellcomeActive = await DialoguesService.isMenuActive(id);
      const dialoguesList = await DialoguesService.listDialogues(id, orderBy);

      setGlobalState('hasWellcome', menuWellcomeActive);

      setHasError(false);
      setDialogues(dialoguesList.filter((el) => !el.codeRelation.includes('-menu')));
    } catch (e) {
      setHasError(true);
      setDialogues([]);
      history.push('/chatbot');
    } finally {
      setIsLoading(false);
    }
  }, [id, orderBy]);

  useEffect(() => {
    loadDialogues();
  }, [loadDialogues]);

  function handleToggleOrderBy() {
    setOrderBy(
      (prevState) => (prevState === 'asc' ? 'desc' : 'asc'),
    );
  }

  function handleChangeSearchTerm(event) {
    setSearchTerm(event.target.value);
  }

  function handleTryAgain() {
    loadDialogues();
  }

  function handleDeleteDialogue(dialogue) {
    setDialogueBeingDelete(dialogue);
    setIsDeleteModalVisible(true);
  }

  function handleIdChatbot() {
    return id;
  }

  async function getDialogue(idChatbot, input, flag) {
    try {
      setIsLoading(true);

      if (input === '') {
        return 'Para poder te ajudar preciso que descreva a sua dúvida, sem texto não consigo adivinhar';
      }

      const dialogue = await DialoguesService
        .getDialogueByIdChatbotAndInput(idChatbot, input, flag);

      return dialogue;
    } catch (e) {
      console.log('errrorrro', e);
      return [];
    } finally {
      setIsLoading(false);
    }
  }

  function handleCloseDeleteModal() {
    setIsDeleteModalVisible(false);
  }

  async function handleConfirmDeleteDialogue() {
    try {
      setIsLoadingDelete(true);

      await DialoguesService.deleteDialogue(dialogueBeingDelete.id);

      setDialogues((prevState) => prevState.filter(
        (dialogue) => dialogue.id !== dialogueBeingDelete.id,
      ));

      handleCloseDeleteModal();

      toast({
        type: 'success',
        text: 'dialogo excluído com sucesso',
      });
    } catch(error) {
      // console.log('errerrerrerrerrerr',error)
      // console.log('error.name', error.name)
      // console.log('error.body', error.body)
      // console.log('error.response', error.response)
      // console.log('error.message', error.message)
      if (error instanceof APIError && error.body.msg.includes('sessão expirou')) {
        toast({
          type: 'danger',
          text: error.body.msg,
        });
        history.push("/chatbot");
      } else {
      history.goBack();
      toast({
        type: 'danger',
        text: 'Ocorreu um erro ao tentar excluir o dialogo',
      });
     }
    } finally {
      setIsLoadingDelete(false);
    }
  }

  async function handleLogout() {
    await LoginService.logout();
    window.location.href = '/chatbot';
  }

  return {
    isLoading,
    isLoadingDelete,
    isDeleteModalVisible,
    handleCloseDeleteModal,
    dialogueBeingDelete,
    handleConfirmDeleteDialogue,
    dialogues,
    searchTerm,
    handleChangeSearchTerm,
    hasError,
    filteredDialogues,
    handleTryAgain,
    orderBy,
    handleToggleOrderBy,
    handleDeleteDialogue,
    handleIdChatbot,
    getDialogue,
    handleLogout,
    hasWellcome,
  };
}
