import {
  useState, useImperativeHandle, useCallback, useEffect,
} from 'react';
import { useHistory } from 'react-router-dom';
import { useGlobalState } from '../../assets/state';
import useErrors from '../../hooks/userErrors';

export default function useMenuDialogueForm(idbot, onSubmit, wellcome, historyUrl,
  buttonsArr, nameMenuParams, id, ref) {
  const [codUser, setCodUser] = useState(0);
  const [idChatbot, setIdChatbot] = useState(idbot);
  const [input, setInput] = useState('');
  const [nameMenu, setNameMenu] = useState('');
  const [idMenu, setIdMenu] = useState('');
  const [output, setOutput] = useState('');
  const [codeCurrent, setCodeCurrent] = useState(0);
  const [codeRelation, setCodeRelation] = useState('n/a');
  const [codeBefore, setCodeBefore] = useState(0);
  const [createdAt, setCreatedAt] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [numberId, setValue] = useState(1);
  const [buttons, setButtons] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [disable, setDisable] = useState(true);
  const [hasWellcome] = useGlobalState('hasWellcome');

  const history = useHistory();

  const loadDialogues = useCallback(async () => {
    try {
      setIsLoading(true);
      if (wellcome) {
        setButtons(hasWellcome);
      } else {
        setButtons(buttonsArr);
      }

      setNameMenu(nameMenuParams);
      setIdMenu(id);
      setHasError(false);
    } catch {
      setHasError(true);
      setButtons([]);
      history.push('/chatbot');
    } finally {
      setIsLoading(false);
    }
  }, [buttonsArr, hasWellcome, id, nameMenuParams, wellcome]);

  useEffect(() => {
    loadDialogues();
  }, [loadDialogues]);

  const {
    errors,
    setError,
    removeError,
    getErrorMessageByFieldName,
  } = useErrors();

  const isFormValid = buttons.length > 0 && output && !errors.length;
  const isNameMenuValid = nameMenu && nameMenu === '-menuWellCome' && !errors.length;

  useImperativeHandle(ref, () => ({
    setFieldsValues: (dialogue) => {
      setNameMenu(nameMenuParams ?? '');
      setIdMenu(id ?? '');
      setCodUser(dialogue.codUser ?? 0);
      setIdChatbot(dialogue.idChatbot ?? idbot);
      setInput(dialogue.input ?? '');
      setOutput(dialogue.output ?? '');
      setCodeCurrent(dialogue.codeCurrent ?? 0);
      setCodeRelation(dialogue.codeRelation ?? nameMenu);
      setCodeBefore(dialogue.codeBefore ?? 0);
      setCreatedAt(dialogue.createdAt ?? '');
    },
    resetFields: () => {
      setIdMenu('');
      setNameMenu('');
      setCodUser(0);
      setIdChatbot('');
      setInput('');
      setOutput('');
      setButtons([]);
      setCodeCurrent(0);
      setCodeRelation('');
      setCodeBefore(0);
      setCreatedAt('');
    },
  }), [id, idbot, nameMenu, nameMenuParams]);

  function handleInputChange(event) {
    setInput(event.target.value);

    if (!event.target.value) {
      setError({ field: 'input', message: 'Pergunta é obrigatória.' });
    } else {
      removeError('input');
    }
  }

  function handleNameMenuChange(event) {
    setNameMenu(event.target.value);

    if (!event.target.value) {
      setError({ field: 'nameMenu', message: 'Nome do menu é obrigatório.' });
      setDisable(true);
    } else {
      setDisable(false);
      removeError('nameMenu');
    }
  }

  function handleOutputChange(event) {
    setOutput(event.target.value);

    if (idChatbot === '' || idbot === '') {
      setIdChatbot(idbot);
    }

    if (!event.target.value) {
      setError({ field: 'output', message: 'Resposta é obrigatória.' });
    } else {
      removeError('output');
    }
  }

  const addBtn = (event) => {
    event.preventDefault();
    setValue(numberId + 1);

    setButtons([...buttons, {
      id: numberId, idChatbot, codeRelation, input: '', output: '',
    }]);
  };

  const handleChangeButtonsInput = (e, element) => {
    const input = document.getElementById(`div${element._id ? element._id : element.id}`);
    const buttonInput = input.children[0].children[0].children[0].children[0].value;

    const obj = buttons.filter((el) => (el
      ._id ? el._id : el.id) === (element._id ? element._id : element.id));

    obj[0].input = buttonInput;
    setInput(obj[0].input);
    if (!e.target.value) {
      setError({ field: `input${element._id ? element._id : element.id}`, message: 'Pergunta é obrigatória.' });
    } else {
      removeError(`input${element._id ? element._id : element.id}`);
    }
  };

  const handleChangeButtonsOutput = (e, element) => {
    const input = document.getElementById(`div${element._id ? element._id : element.id}`);
    const buttonOutput = input.children[1].children[0].children[0].children[0].value;

    const obj = buttons.filter((el) => (el
      ._id ? el._id : el.id) === (element._id ? element._id : element.id));

    obj[0].output = buttonOutput;
    setOutput(obj[0].output);
    if (!e.target.value) {
      setError({ field: `output${element._id ? element._id : element.id}`, message: 'Resposta é obrigatória.' });
    } else {
      removeError(`output${element._id ? element._id : element.id}`);
    }
  };

  const handleRemoveOptionMenu = async (idPosition) => {
    setIsLoading(true);
    const arr = buttons.filter((_, index) => index !== idPosition);
    setValue(numberId - 1);
    setButtons(arr);
    setIsLoading(false);
  };

  async function handleSubmit(event) {
    event.preventDefault();

    setIsSubmitting(true);
    if (!wellcome && nameMenu === '') {
      setError({ field: 'nameMenu', message: 'Nome é obrigatório' });
      setIsSubmitting(false);
    } else {
      const elementsInvalid = buttons.filter((el) => el.input === '' || el.output === '');

      if (elementsInvalid.length > 0) {
        elementsInvalid.forEach((elv) => {
          setError({ field: `output${elv.id}`, message: 'Opção inválida. Digite um nome para opção e resposta não pode estar vazia' });
        });
        setIsSubmitting(false);
      } else {
        await onSubmit({
          codUser,
          idChatbot,
          buttons,
          codeCurrent,
          codeRelation: wellcome ? '-menuWellCome' : `-menu${nameMenu}`,
          codeBefore,
          createdAt,
        });

        setIsSubmitting(false);
        history.push(historyUrl);
      }
    }
  }

  return {
    handleSubmit,
    getErrorMessageByFieldName,
    codUser,
    isSubmitting,
    input,
    handleInputChange,
    output,
    handleOutputChange,
    codeCurrent,
    codeRelation,
    codeBefore,
    createdAt,
    isFormValid,
    isNameMenuValid,
    idChatbot,
    addBtn,
    buttons,
    numberId,
    handleChangeButtonsInput,
    handleChangeButtonsOutput,
    handleRemoveOptionMenu,
    hasError,
    isLoading,
    handleNameMenuChange,
    disable,
    nameMenu,
    idMenu,
  };
}
