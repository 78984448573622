import * as S from './styles';

import { Link } from 'react-router-dom';
import Loader from '../../components/Loader';
import Modal from '../../components/Modal';
import useHome from './useHome';
import InputSearch from './components/InputSearch';
import Header from './components/Header';
import ErrorStatus from './components/ErrorStatus';
import EmptyList from './components/EmptyList';
import SearchNotFound from './components/SearchNotFound';
import ChatbotList from './components/ChatbotList';
import Button from '../../components/Button';
import MenuHamburguer from '../../components/MenuHamburguer';

export default function Home() {
  const {
    isLoading,
    isLoadingDelete,
    isDeleteModalVisible,
    chatbotBeingDelete,
    handleCloseDeleteModal,
    handleConfirmDeleteChatbot,
    chatbots,
    searchTerm,
    handleChangeSearchTerm,
    hasError,
    filteredChatbots,
    handleTryAgain,
    orderBy,
    handleToggleOrderBy,
    handleDeleteChatbot,
    handleLogout,
    isTermoModalVisible,
    handleCloseTermoModal,
    handleConfirmTermoModal
  } = useHome();

  const hasChatbots = chatbots.length > 0;
  const isListEmpty = !hasError && (!isLoading && !hasChatbots);
  const isSearchEmpty = !hasError && (hasChatbots && filteredChatbots.length < 1);

  return (
    <S.Container>
      <MenuHamburguer />
      <Loader isLoading={isLoading} />

      {hasChatbots && (
        <InputSearch
          value={searchTerm}
          onChange={handleChangeSearchTerm}
        />
      )}

      <Header
        hasError={hasError}
        qtyOfChatbots={chatbots.length}
        qtyOfFilteredChatbots={filteredChatbots.length}
      />

      {hasError && (<ErrorStatus onTryAgain={handleTryAgain} />)}
      {isListEmpty && <EmptyList />}
      {isSearchEmpty && <SearchNotFound searchTerm={searchTerm} />}

      {hasChatbots && (
        <>
          <ChatbotList
            filteredChatbots={filteredChatbots}
            orderBy={orderBy}
            onToggleOrderBy={handleToggleOrderBy}
            onDeleteChatbot={handleDeleteChatbot}
          />

          <Modal
            danger
            isLoading={isLoadingDelete}
            visible={isDeleteModalVisible}
            title={`Tem certeza que deseja remover o chatbot "${chatbotBeingDelete?.nome}"?`}
            confirmLabel="Deletar"
            onCancel={handleCloseDeleteModal}
            onConfirm={handleConfirmDeleteChatbot}
          >
            <h2>Esta ação não poderá ser desfeita</h2>
          </Modal>
        </>
      )}

      {/* {hasChatbots && ( */}
        <>
          <Modal
            danger
            visible={isTermoModalVisible}
            title={`Ao clicar em Aceitar você autoriza os Termos de uso.`}
            confirmLabel="Aceitar"
            onConfirm={handleConfirmTermoModal}
            onCancel={handleCloseTermoModal}
          >
            <Link to="/chatbot/termos">Termos de uso</Link>
          </Modal>
        </>
      {/* )} */}

      {/* <div className="logout">
        <Button onClick={handleLogout}>Sair</Button>
      </div> */}

    </S.Container>
  );
}
