import { useState } from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';
import useErrors from '../../hooks/userErrors';

import isEmailValid from '../../utils/isEmailValid';

import FormGroup from '../FormGroup';
import Input from '../Input';
import Button from '../Button';

import * as S from './styles';
import emailIcon from '../../assets/images/icons/icon-nova-mensagem.png';

export default function LoginForm({ buttonLabel, onSubmit }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isEmailOrIdentity, setIsEmailOrIdentity] = useState(true);
  const [isFormValid, setIsFormValid] = useState(false);
  const [btnMercadoPago, setBtnMercadoPago] = useState(false);
  const [msg, setMsg] = useState("");

  const {
    errors,
    setError,
    removeError,
    getErrorMessageByFieldName,
  } = useErrors();

  function handleEmailChange(event) {
    let valor = event.target.value;
    valor.trim();
    setEmail(valor);

    if (valor && !isEmailValid(valor)) {
      setError({ field: 'email', message: 'E-mail inválido.' });
    } else {
      removeError('email');
      console.log('errors',errors)
      if(!errors.length) {
        setIsFormValid(true);
    }
  }
}

  // function handleToggleChange() {
  //     removeError('cpfOrCnpj');
  //     removeError('email');
  //   setIsEmailOrIdentity(!isEmailOrIdentity);
  // }

  // function handleCpfOrCnpjChange(event) {
  //   let valor = event.target.value.length > 14 ? formatCnpj(event.target.value) : formatCpf(event.target.value);

  //   setCpfOrCnpj(valor);

  //   if (valor && !isCpfOrCnpjValid(valor)) {
  //     setError({ field: 'cpfOrCnpj', message: 'Cpf ou Cnpj inválido.' });
  //   } else {
  //     removeError('cpfOrCnpj');
  //     removeError('email');
  //     if(!errors.length) {
  //       setIsFormValid(true);
  //   }
  //   }
  // }

  function handlePasswordChange(event) {
    let valor = event.target.value;
    valor.trim();
    setPassword(valor);

    if (!valor || valor.length < 6) {
      setError({ field: 'password', message: 'Senha é obrigatório.' });
      setIsFormValid(false);
    } else {
      removeError('password');
      setIsFormValid(true);
    }
  }

  async function handleSubmit(event) {
    event.preventDefault();

    setIsSubmitting(true);

    
    if (password == "" || password.length < 6) {
      setError({ field: 'password', message: 'Senha inválida ou vazia. A mesma deve conter pelo menos 6 caracteres' });
      setIsSubmitting(false);
      setIsFormValid(false);
      return;
    } else {
      removeError('password');
      setIsFormValid(true);
      if(!errors.length) {
        setIsFormValid(true);
    }
  }
    
    const mensagem = await onSubmit({
      dadosUser: {
        email, password
      }
    });

    if (mensagem && mensagem != "") {
      setIsSubmitting(false);
      setMsg(mensagem);
      if (mensagem.includes("não foi identificado o pagamento")) {
        setBtnMercadoPago(true);
      }
    } else {
      setIsSubmitting(false);
      setBtnMercadoPago(false);
    }
    
  }

  return (
    <S.Form onSubmit={handleSubmit} noValidate>
      <S.ToggleContainer>
      <S.Icon src={emailIcon} alt={'Email'} /><span>Login por E-mail</span>
      </S.ToggleContainer>

      <FormGroup error={getErrorMessageByFieldName('email')}>
        <Input
          type="text"
          error={getErrorMessageByFieldName('email')}
          placeholder="E-mail"
          value={email}
          onChange={handleEmailChange}
          disabled={isSubmitting}
        />
      </FormGroup>
      
      <FormGroup error={getErrorMessageByFieldName('password')}>
        <Input
          type="password"
          autoComplete="new-password"
          error={getErrorMessageByFieldName('password')}
          placeholder="Senha *"
          minLength={6}
          value={password}
          onChange={handlePasswordChange}
          disabled={isSubmitting}
        />
      </FormGroup>

      <S.ButtonContainer>
        <Button
          type="submit"
          disabled={!isFormValid}
          isLoading={isSubmitting}
        >
          {buttonLabel}
        </Button>
        <Link to="/chatbot/signup">Não tem conta? Criar</Link>
      </S.ButtonContainer>
        <S.EsqueciSenha><a href="/chatbot/solicitar-senha">Esqueci minha senha</a></S.EsqueciSenha>

      { btnMercadoPago ?
        <>
        {msg ? <p className='message-error'>{msg}</p> : <></>}
        <S.ButtonContainer>
        {msg.includes('1,00') ? 
          <a href="https://www.mercadopago.com.br/checkout/v1/redirect?pref_id=1561350092-eaa1cd05-7199-4847-bc5e-bd5abc9d34a7" target="_blank">Pagar com Mercado Pago</a>
          :
          <a href="https://www.mercadopago.com.br/checkout/v1/redirect?pref_id=1561350092-a268fd34-5c42-44c5-bc30-8e0a3cc2bb44" target="_blank">Pagar com Mercado Pago</a>
        }
        </S.ButtonContainer>
        </>
        :
        <></>
      }
      { !btnMercadoPago ?
        <>
        {msg ? <p className='message-error'>{msg}</p> : <></>}
        </>
        :
        <></>
      }
    </S.Form>
  );
}

LoginForm.propTypes = {
  buttonLabel: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
};
