import { useEffect } from 'react';
import * as S from './styles';

// import logo from '../../assets/images/logo.svg';
// import { element } from 'prop-types';

export default function Header() {
  useEffect(() => {
    async function typeWrite(el) {
      const textArray = el.innerHTML.split('');
      el.innerHTML = '';

      textArray.forEach((letter, i) => {
        setTimeout(() => (el.innerHTML += letter), 95 * i)
      });

    }

    typeWrite(document.getElementById('elementEl'));
  }, []);

  return (
    <S.Container>
      {/* <img src={logo} alt="logo" width="201" /> */}
      <h1 id='elementEl'>Bem vindo ao seu chatbot.</h1>
    </S.Container>
  );
}
