import ChatbotMapper from './mappers/ChatbotMapper';
import HttpClient from './utils/HttpClient';

class ChatbotsService {
  constructor() {
    this.httpClient = new HttpClient(process.env.REACT_APP_BASE_URL_API_GTW);
  }

  async listChatbots(orderBy) {
    const t = localStorage.getItem('token');
    const h = { Authorization: `Bearer ${t}` };
    const chatbots = await this.httpClient.get(`/chatbots/all/${orderBy}`, { headers: h });

    return chatbots.map(ChatbotMapper.toDomain);
  }

  createChatbot(chatbot) {
    const body = ChatbotMapper.toPersistence(chatbot);
    const t = localStorage.getItem('token');
    const h = { Authorization: `Bearer ${t}` };
    return this.httpClient.post('/chatbots', { body, headers: h });
  }

  async getChatbotById(id) {
    const t = localStorage.getItem('token');
    const h = { Authorization: `Bearer ${t}` };
    const chatbot = await this.httpClient.get(`/chatbots/${id}`, { headers: h });

    return ChatbotMapper.toDomain(chatbot);
  }

  editChatbot(id, chatbot) {
    const body = ChatbotMapper.toPersistence(chatbot);
    const t = localStorage.getItem('token');
    const h = { Authorization: `Bearer ${t}` };
    return this.httpClient.put(`/chatbots/${id}`, { body, headers: h });
  }

  deleteChatbot(id) {
    const t = localStorage.getItem('token');
    const h = { Authorization: `Bearer ${t}` };
    return this.httpClient.delete(`/chatbots/${id}`, { headers: h });
  }
}

export default new ChatbotsService();
