import HttpClient from './utils/HttpClient';

class CheckServerService {
  constructor() {
    this.httpClient = new HttpClient(process.env.REACT_APP_BASE_URL_API_GTW);
  }

  checkServer() {
    return this.httpClient.get('/do-check-server');
  }
}
export default new CheckServerService();
