import { useQuery } from '../../../../../hooks/useQuery';
import DialoguesService from '../../../../../services/DialoguesService';

import * as S from './styles';

export default function ModalChatbotClient() {
  const query = useQuery();

  async function getDialogue(idChatbot, input, flag) {
    try {
      if (input === '') {
        return 'Para poder te ajudar preciso que descreva a sua dúvida, sem texto não consigo adivinhar';
      }

      const dialogue = await DialoguesService
        .getDialogueByIdChatbotAndInput(idChatbot, input, flag);
      if (dialogue.output === undefined) {
        return 'Seja mais especifico na sua pesquisa';
      }
      return dialogue.output;
    } catch {
      return 'Não sei te responder';
    } finally {
      console.log('finalizado');
    }
  }

  function perguntar() {
    const Input = document.getElementById('input');
    const question = Input.value.toString().trim();

    const msg = document.getElementById('msg');

    let msgLines = msg.innerHTML;
    msgLines = msgLines.replace('<a href="#" id="end">', '');

    const input = question;
    const output = getDialogue(query.get('id'), question, 'input');
    output.then(async (data) => {
      msgLines
        += `
          <div class="talk-bubble tri-right right-top">
          <div class="talktext-input">
            <p>${input}</p>
          </div>
        </div>
        <div class="talk-bubble tri-right left-top">
        <div class="talktext-output">
          <p>${data}</p>
        </div>
      </div>

       <a href="#" id="end">
          `;
      document.getElementById('input').value = '';
      msg.innerHTML = msgLines;
      window.location.href = '#end';
      document.getElementById('input').focus();
    }).catch((error) => {
      console.log('error output: ', error);
    });
  }

  function sendMessage(e) {
    if (e.keyCode === 13) {
      perguntar();
      return false;
    }
    return true;
  }

  return (
    <S.Container
      danger={false}
      isLeaving={!true}
    >
      <div className="box-dialogue-example">
        <div className="header-box">
          <span className="dot">
            &nbsp;
          </span>
          Chatbot está online
        </div>
        <div className="body-box">
          <div className="scroll-messages" id="msg">
            <div className="talk-bubble tri-right left-top">
              <div className="talktext-output">
                <p>Olá! Em que posso ajudar?</p>
              </div>
            </div>
          </div>
          <input
            className="input-message"
            type="text"
            id="input"
            autoFocus
            onKeyDown={(e) => sendMessage(e)}
            placeholder="Digite a sua mensagem e tecle Enter..."
          />
        </div>
        {/* <input type="hidden" id="code_user" value="[code_user]" />
          <input type="hidden" id="code_before" value="0" /> */}
      </div>
    </S.Container>
  );
}
