import * as S from './styles';

// import logo from '../../assets/images/logo.svg';
// import { element } from 'prop-types';

export default function FooterSite() {
  return (
    <S.Container>
      <div class="row text-center">
          <div class="col-sm-4">
              <p class="copyright1"> &copy; 2024 FBMStore - Todos os direitos reservados.</p>
          </div>
      </div>
    </S.Container>
  );
}
