import { useParams, useHistory } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';

import ChatbotsService from '../../services/ChatbotsService';
import toast from '../../utils/toast';
import useSafeAsyncAction from '../../hooks/useSafeAsyncAction';
import APIError from '../../errors/APIError';
import LoginService from '../../services/LoginService';

export default function useEditChatbot() {
  const [isLoading, setIsLoading] = useState(true);
  const [chatbotName, setChatbotName] = useState(true);
  const chatbotFormRef = useRef(null);

  const { id } = useParams();
  const history = useHistory();
  const safeAsyncAction = useSafeAsyncAction();

  useEffect(() => {
    async function loadChatbot() {
      try {
        const chatbot = await ChatbotsService.getChatbotById(id);

        safeAsyncAction(() => {
          chatbotFormRef.current.setFieldsValues(chatbot);
          setIsLoading(false);
          setChatbotName(chatbot.nome);
        });
      } catch (error) {
        safeAsyncAction(() => {
          history.push('/chatbot/home');
          toast({
            type: 'danger',
            text: 'Chatbot não encontrado!',
          });
        });
      }
    }

    loadChatbot();
  }, [id, history, safeAsyncAction]);

  async function handleSubmit(chatbot) {
    try {
      const chatbotData = await ChatbotsService.editChatbot(id, chatbot);
      setChatbotName(chatbotData.nome);
      toast({
        type: 'success',
        text: 'Chatbot atualizado com sucesso!',
      });
    } catch (error) {
      if (error instanceof APIError && error.body.msg.includes('sessão expirou')) {
        toast({
          type: 'danger',
          text: error.body.msg,
        });
        history.push("/chatbot");
      } else {
        toast({
          type: 'danger',
          text: 'Ocorreu um erro ao tentar atualizar o chatbot.',
        });
      }
    }
  }

  async function handleLogout() {
    await LoginService.logout();
    window.location.href = '/chatbot';
  }

  return {
    isLoading,
    chatbotName,
    chatbotFormRef,
    handleSubmit,
    handleLogout,
  };
}
