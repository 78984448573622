import { Switch, Route } from 'react-router-dom';

import App from './components/App';

export default function RoutesSite() {
  return (
    <Switch>
      <Route path="/chatbot" exact component={App} />
    </Switch>
  );
}
