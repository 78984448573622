import { useRef } from 'react';
import { useHistory } from 'react-router-dom';
import APIError from '../../errors/APIError';

import ChatbotsService from '../../services/ChatbotsService';
import LoginService from '../../services/LoginService';
import toast from '../../utils/toast';

export default function useNewChatbot() {
  const history = useHistory();
  const chatbotFormRef = useRef(null);

  async function handleLogout() {
    await LoginService.logout();
    window.location.href = '/chatbot';
  }

  async function handleSubmit(chatbot) {
    try {
      await ChatbotsService.createChatbot(chatbot);

      chatbotFormRef.current.resetFields();
      toast({
        type: 'success',
        text: 'Chatbot cadastrado com sucesso!',
      });
    } catch (error) {
      if (error instanceof APIError && error.body.msg.includes('sessão expirou')) {
        toast({
          type: 'danger',
          text: error.body.msg,
        });
        history.push("/chatbot");
      } else {
        toast({
          type: 'danger',
          text: 'Ocorreu um erro ao cadastrar o chatbot.',
        });
      }
    }
  }
  return {
    chatbotFormRef,
    handleSubmit,
    handleLogout,
  };
}
