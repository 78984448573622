import { useEffect, useState } from 'react';
import PageHeader from '../../components/PageHeader';
import LoginForm from '../../components/LoginForm';

import LoginService from '../../services/LoginService';
import toast from '../../utils/toast';
import APIError from '../../errors/APIError';

export default function Login() {
  const [totalTimeInSeconds, setTotalTimeInSeconds] = useState(10);
  const [contador, setContador] = useState(false);
  const [email, setEmail] = useState('');
  const [resultadoLogin, setResultadoLogin] = useState(false);
  const minutes = Math.floor(totalTimeInSeconds / 60);
  const seconds = totalTimeInSeconds % 60;

  async function handleSubmit(formData) {
    try {
      const tokenResult = await LoginService.login(formData);

      if(tokenResult.token == "") {
        // toast({
        //   type: 'danger',
        //   text: tokenResult.msg == "" ? "Houve um erro inesperado ao fazer login, caso persista entre em contato com (11) 9 4468-8144 WhatsApp" : tokenResult.msg,
        // }); 
        let msg = tokenResult.msg == "" ? "Houve um erro inesperado ao fazer login, caso persista entre em contato com (11) 9 4468-8144 WhatsApp" : tokenResult.msg;
        // if(tokenResult.msg.includes('não foi identificado o pagamento')){
          return msg;
        // } else {
        //   return false;
        // }
      } else {
        localStorage.setItem('token', tokenResult.token);

        window.location.href = '/chatbot/home';
        return "";
      }
      
    } catch (error) {
      if (error instanceof APIError && error.body.msg.includes('não foi identificado o pagamento')) {
        toast({
          type: 'danger',
          text: error.body.msg,
        });
          setContador(true);
          setEmail(formData.email);
      } else {
        toast({
          type: 'danger',
          text: 'Ocorreu um erro ao tentar logar.',
        });
      }
    }
  }

  useEffect(() => {
    if (contador) {
      if (totalTimeInSeconds === 0) {
        window.location.href = `/chatbot/payments/${email}`;
      }
      setTimeout(() => {
        setTotalTimeInSeconds(totalTimeInSeconds - 1);
      }, 1000);
    }
  }, [contador, email, totalTimeInSeconds]);

  return (
    <>
      <PageHeader
        historyUrl="/chatbot"
        title="Login"
      />

      <LoginForm
        buttonLabel="Entrar"
        onSubmit={handleSubmit}
      />
      {contador
        ? (
          <div>
            <span>{minutes.toString().padStart(2, '0')}</span>
            <span>:</span>
            <span>{seconds.toString().padStart(2, '0')}</span>
          </div>
        ) : null}
    </>
  );
}
