import styled from 'styled-components';

export const Container = styled.div`
   body {
      font-family: Arial, sans-serif;
      line-height: 1.6;
      margin: 0;
      padding: 20px;
    }

    h1 {
      font-size: 24px;
      margin-bottom: 20px;
    }

    h2 {
      font-size: 20px;
      margin-bottom: 10px;
    }

    p {
      margin-bottom: 10px;
    }

    ul {
      margin-bottom: 10px;
      padding-left: 20px;
    }

    li {
      margin-bottom: 5px;
    }
`;
