import { useState, useImperativeHandle } from 'react';
import useErrors from '../../hooks/userErrors';

export default function useDialogueForm(chatbotId, onSubmit, ref) {
  const [codUser, setCodUser] = useState(0);
  const [idChatbot, setIdChatbot] = useState('');
  const [input, setInput] = useState('');
  const [output, setOutput] = useState('');
  const [codeCurrent, setCodeCurrent] = useState(0);
  const [codeRelation, setCodeRelation] = useState('n/a');
  const [codeBefore, setCodeBefore] = useState(0);
  const [createdAt, setCreatedAt] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    errors,
    setError,
    removeError,
    getErrorMessageByFieldName,
  } = useErrors();

  const isFormValid = input && output && !errors.length;

  useImperativeHandle(ref, () => ({
    setFieldsValues: (dialogue) => {
      setCodUser(dialogue.codUser ?? 0);
      setIdChatbot(dialogue.idChatbot ?? '');
      setInput(dialogue.input ?? '');
      setOutput(dialogue.output ?? '');
      setCodeCurrent(dialogue.codeCurrent ?? 0);
      setCodeRelation(dialogue.codeRelation ?? 'n/a');
      setCodeBefore(dialogue.codeBefore ?? 0);
      setCreatedAt(dialogue.createdAt ?? '');
    },
    resetFields: () => {
      setCodUser(0);
      setIdChatbot('');
      setInput('');
      setOutput('');
      setCodeCurrent(0);
      setCodeRelation('n/a');
      setCodeBefore(0);
      setCreatedAt('');
    },
  }), []);

  function handleInputChange(event) {
    setInput(event.target.value);

    if (!event.target.value) {
      setError({ field: 'input', message: 'Pergunta é obrigatória.' });
    } else {
      removeError('input');
    }
  }

  function handleOutputChange(event) {
    setOutput(event.target.value);

    if (idChatbot === '' || chatbotId === '') {
      setIdChatbot(chatbotId);
    }

    if (!event.target.value) {
      setError({ field: 'output', message: 'Resposta é obrigatória.' });
    } else {
      removeError('output');
    }
  }

  async function handleSubmit(event) {
    event.preventDefault();

    setIsSubmitting(true);

    await onSubmit({
      codUser,
      idChatbot,
      input,
      output,
      codeCurrent,
      codeRelation,
      codeBefore,
      createdAt,
    });

    setIsSubmitting(false);
  }
  return {
    handleSubmit,
    getErrorMessageByFieldName,
    codUser,
    isSubmitting,
    input,
    handleInputChange,
    output,
    handleOutputChange,
    codeCurrent,
    codeRelation,
    codeBefore,
    createdAt,
    isFormValid,
  };
}
