import { useEffect, useState } from 'react';
import CheckServerService from '../../services/CheckServerService';
import toast from '../../utils/toast';

export default function CheckServerIsOn() {
  const [msg, setMsg] = useState("");
    async function check(e) {
      e.preventDefault()
      try {
           const resultCheckServer = await CheckServerService.checkServer();
           setMsg(resultCheckServer.msg);
      } catch {
        toast({
          type: 'danger',
          text: 'Ocorreu algum erro ao tentar checar se o server está online',
        });
      } finally {
        console.log('feshow check');
      }
    }

  return (
    <>
    <button onClick={(e) => check(e)}>Checar</button>
      <span>{msg}</span>
    </>
  );
}
