import { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import Button from '../../components/Button';
import FormGroup from '../../components/FormGroup';
import Input from '../../components/Input';
import PageHeader from '../../components/PageHeader';
import APIError from '../../errors/APIError';
import useErrors from '../../hooks/userErrors';
import ActiveAccountService from '../../services/ActiveAccountService';
import EmailService from '../../services/EmailService';
import UserService from '../../services/UserService';
import isEmailValid from '../../utils/isEmailValid';
import toast from '../../utils/toast';

export default function ActiveAccount() {
  const history = useHistory();
  const { token } = useParams();
  const [email, setEmail] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    errors,
    setError,
    removeError,
    getErrorMessageByFieldName,
  } = useErrors();

  const isFormValid = email && !errors.length;

  function handleEmailChange(event) {
    setEmail(event.target.value);

    if (event.target.value && !isEmailValid(event.target.value)) {
      setError({ field: 'email', message: 'E-mail inválido.' });
    } else {
      removeError('email');
    }
  }

  useEffect(() => {
    async function validAccount() {
      try {
        await ActiveAccountService.activeAccount(token);

        toast({
          type: 'success',
          text: 'Conta ativada com sucesso.',
        });

        setTimeout(() => {
          window.location.href = '/chatbot';
        }, 2000);
      } catch (error) {
        console.log('error', error);
        if (error instanceof APIError && error.body.msg.includes('sessão expirou')) {
          toast({
            type: 'danger',
            text: error.body.msg,
          });
          history.push("/chatbot");
        } else {
          toast({
            type: 'danger',
            text: 'Ocorreu um erro ao tentar ativar a conta.',
          });
        }
      } finally {
        console.log('fechando conexão');
      }
    }

    validAccount();
  }, [token]);

  async function handleSubmit(event) {
    event.preventDefault();

    setIsSubmitting(true);

    try {
      const user = await UserService.getNewToken({ email });
      await EmailService.send(user);

      toast({
        type: 'success',
        text: 'Foi enviado um e-mail com instruções para o login.',
      });

      setIsSubmitting(false);

      setTimeout(() => {
        window.location.href = '/chatbot';
      }, 10000);
    } catch (error) {
      toast({
        type: 'danger',
        text: 'Ocorreu um erro ao tentar reenviar token.',
      });
    }
  }

  return (
    <>
      <PageHeader
        historyUrl="/chatbot"
        title="ActiveAccount"
      />
      <FormGroup error={getErrorMessageByFieldName('email')}>
        <Input
          type="email"
          error={getErrorMessageByFieldName('email')}
          placeholder="E-mail"
          value={email}
          onChange={handleEmailChange}
          disabled={isSubmitting}
        />
      </FormGroup>
      <Button
        type="submit"
        disabled={!isFormValid}
        isLoading={isSubmitting}
        onClick={(e) => handleSubmit(e)}
      >
        Reenviar token
      </Button>
    </>
  );
}
