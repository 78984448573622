import DialogueMapper from './mappers/DialogueMapper';
import MenuDialogueMapper from './mappers/MenuDialogueMapper';
import HttpClient from './utils/HttpClient';

class MenuDialoguesService {
  constructor() {
    this.httpClient = new HttpClient(process.env.REACT_APP_BASE_URL_API_GTW);
  }

  async listMenuDialogues(id, orderBy) {
    const t = localStorage.getItem('token');
    const h = { Authorization: `Bearer ${t}` };
    const menuDialogues = await this.httpClient.get(`/dialogues/chatbot/${id}/menus/${orderBy}`, { headers: h });
    return menuDialogues.map(MenuDialogueMapper.toDomain);
  }

  createUpadteMenuDialogue(menuDialogue) {
    const body = MenuDialogueMapper.toPersistence(menuDialogue);
    const t = localStorage.getItem('token');
    const h = { Authorization: `Bearer ${t}` };
    return this.httpClient.post('/dialogues/menu', { body, headers: h });
  }

  async getMenuDialogueByCodeRelation(nameMenu, orderBy) {
    const t = localStorage.getItem('token');
    const h = { Authorization: `Bearer ${t}` };
    const menuDialogues = await this.httpClient.get(`/dialogues/menu/${nameMenu}/${orderBy}`, { headers: h });
    return menuDialogues.map(DialogueMapper.toDomain);
  }

  editMenuDialogue(id, codeRelation, menuDialogue) {
    const body = MenuDialogueMapper.toPersistence(menuDialogue);
    const t = localStorage.getItem('token');
    const h = { Authorization: `Bearer ${t}` };
    return this.httpClient.put(`/dialogues/menu/${id}/${codeRelation}`, { body, headers: h });
  }

  deleteMenuDialogue(nameMenu, idChatbot) {
    const t = localStorage.getItem('token');
    const h = { Authorization: `Bearer ${t}` };
    return this.httpClient.delete(`/dialogues/name-menu/${nameMenu}/${idChatbot}`, { headers: h });
  }
}

export default new MenuDialoguesService();
