import { ThemeProvider } from 'styled-components';
import { BrowserRouter } from 'react-router-dom';

import HeaderSite from './HeaderSite';
import BodySite from './BodySite';
import FooterSite from './FooterSite';
import RoutesSite from '../../RoutesSite';
import ToastContainer from '../Toast/ToastContainer';

import GlobalStyles from '../../assets/styles/global';
import defaultTheme from '../../assets/styles/themes/default';

import * as S from './styles';

export default function Site() {
  // console.log('window.location: ',window.location)
  return (
    <BrowserRouter>
      <ThemeProvider theme={defaultTheme}>
        <GlobalStyles />
        <ToastContainer />

        <S.Container>
          <HeaderSite />
          <S.Separator />
          <BodySite />
          <FooterSite />
          <RoutesSite />
        </S.Container>

      </ThemeProvider>
    </BrowserRouter>
  );
}
