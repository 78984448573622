import PageHeaderRedefinirSenha from '../../components/PageHeaderRedefinirSenha';

import RedefinirSenhaService from '../../services/RedefinirSenhaService';
import toast from '../../utils/toast';
import RedefinirSenhaForm from '../../components/RedefinirSenhaForm';
import { useEffect, useState } from 'react';

export default function RedefinirSenha() {
  const [token, setToken] = useState("");
  useEffect(() => {
    async function loadToken() {
      // try {
        const tokenParam = window.location.search;
        const token = tokenParam.replace("?token=", "");

        setToken(token);
      // } catch {} finally {
      //   setIsLoadingCategories(false);
      // }
    }

    loadToken();
  }, []);

  async function handleSubmit(formData) {
    try {
      const result = await RedefinirSenhaService.redefinir(formData);


      toast({
        type: 'success',
        text: result.msg,
      });

    } catch (error) {
      toast({
        type: 'danger',
        text: 'Ocorreu um erro ao tentar redefinir a senha. Caso persista entre em contato pelo (11) 9 4468-8144 WhatSapp informando o ocorrido.',
      });
    }
  }

  return (
    <>
      <PageHeaderRedefinirSenha
        historyUrl="/chatbot"
        title="Redefinir Senha"
      />

      <RedefinirSenhaForm
        buttonLabel="Redefinir Senha"
        onSubmit={handleSubmit}
        token={token}
      />
    </>
  );
}
