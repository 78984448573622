import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Sora', sans-serif;
  }

  body {
    background: ${({ theme }) => theme.colors.background};
    font-size: 16px;
    color: ${({ theme }) => theme.colors.gray[900]};
  }

  button {
    cursor: pointer;
  }

  .dot {
  height: 13px;
  width: 13px;
  background-color: lightgreen;
  border-radius: 50%;
  display: inline-block;
  }

  /* css de balão de diálogo */
  .talk-bubble {
  margin: 10px;
  display: inline-block;
  position: relative;
  width: 80%;
  height: auto;
  color: white;
  overflow-wrap: break-word;
  word-break: break-word;
  }

  .tri-right.border.left-top:before {
  content: ' ';
  position: absolute;
  width: 0;
  height: 0;
  left: -40px;
  right: auto;
  top: -8px;
  bottom: auto;
  border: 32px solid;
  border-color: #666 transparent transparent transparent;
  overflow-wrap: break-word;
  word-break: break-word;
  }
  .tri-right.left-top:after{
  content: ' ';
  position: absolute;
  width: 0;
  height: 0;
  left: -10px;
  right: auto;
  top: 0px;
  bottom: auto;
  border: 22px solid;
  border-color: #00aabb transparent transparent transparent;
  overflow-wrap: break-word;
  word-break: break-word;
  }
  .tri-right.border.right-top:before {
  content: ' ';
  position: absolute;
  width: 0;
  height: 0;
  left: auto;
  right: -40px;
  top: -8px;
  bottom: auto;
  border: 32px solid;
  border-color: #666 transparent transparent transparent;
  overflow-wrap: break-word;
  word-break: break-word;
  }
  .tri-right.right-top:after{
  content: ' ';
  position: absolute;
  width: 0;
  height: 0;
  /* left: auto;
  right: -59px; */
  margin-left: 105%;
  top: 0px;
  bottom: auto;
  border: 20px solid;
  border-color: #8000ff transparent transparent transparent;
  overflow-wrap: break-word;
  word-break: break-word;
  }
  .talktext-output{
  padding: 1em;
  text-align: left;
  line-height: 1.5em;
  width: 80%;
  margin-left: 10px;
  height: auto;
  background-color: #00aabb;
  }
  .talktext-output p{
  -webkit-margin-before: 0em;
  -webkit-margin-after: 0em;
  }
  .talktext-input{
  padding: 1em;
  text-align: right;
  line-height: 1.5em;
  width: 80%;
  height: auto;
  background-color: #8000ff;
  margin-left: 32%;
  }
  .talktext-input p{
  -webkit-margin-before: 0em;
  -webkit-margin-after: 0em;
  }
  avatar-msg {
  display: flex;
  }
  .botao {
  display: inline-block;
  padding: 5px;
  font-size: 18px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  outline: none;
  color: #fff;
  background-color: #8000ff;
  border: none;
  border-radius: 25px;
  }
  .botao:hover {background-color: #8000ff}
  .box-dialogue{
    width: 410px;
    height: 450px;
    position: fixed;
    border-radius: 5px;
    z-index: 1000;
    right: 1rem;
    bottom: 1rem;
    background-color: #fff;
    box-shadow: 0 9px 9px #999;
  }
  .box-dialogue-example{
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 1000;
    background-color: #fff;
  }
  .div-myButton {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .dot-strong {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 0.5em;
  }
  .header-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border-bottom: 2px solid ${({ theme }) => theme.colors.gray[100]};
  }
  .header-box strong {
    margin-left: 10px;
  }
  .body-box {
    width: 100%;
  }
  .scroll-messages {
    overflow-y:scroll;
    overflow-x: hidden;
    height: 400px;
    width: 100%;
    align-items:"center";
    border-bottom: 2px solid ${({ theme }) => theme.colors.gray[100]};
  }
  .send-button:hover {
    background-color: #128C7E; /* Tom mais escuro de verde para hover */
  }
  .myButton {
    width:20px;
    height:20px;
    position:relative;
    border-radius:6px;
    cursor: pointer;
}
  .myButton-mobile {
    width:20px;
    height:20px;
    position:relative;
    border-radius:6px;
    cursor: pointer;
}

.myButton:before,.myButton:after{
    content:'';
    position:absolute;
    width:18px;
    height:2px;
    background-color:#000;
    border-radius:2px;
    top:8px;
    box-shadow:0 0 2px 0 #ccc;
}
.myButton-mobile:before,.myButton-mobile:after{
    content:'';
    position:absolute;
    width:18px;
    height:2px;
    background-color:#fff;
    border-radius:2px;
    top:8px;
    box-shadow:0 0 2px 0 #ccc;
}

.myButton:before{
    -webkit-transform:rotate(45deg);
    -moz-transform:rotate(45deg);
    transform:rotate(45deg);
    left:2px;
}
.myButton:after{
    -webkit-transform:rotate(-45deg);
    -moz-transform:rotate(-45deg);
    transform:rotate(-45deg);
    right:2px;
}
.myButton-mobile:before{
    -webkit-transform:rotate(45deg);
    -moz-transform:rotate(45deg);
    transform:rotate(45deg);
    left:2px;
}
.myButton-mobile:after{
    -webkit-transform:rotate(-45deg);
    -moz-transform:rotate(-45deg);
    transform:rotate(-45deg);
    right:2px;
}
.logout{
  position: fixed;
  right: 1rem;
  top: 1rem;
  z-index: 1000;
}

.menu-wellcome {
  display: flex;
  align-items: center;
  justify-content: center;
}
.menu-wellcome li {
  background-color: #8000ff;
  list-style-type: none;
  width: 300px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  border-radius: 10px;
  cursor: pointer;
}
.menu-wellcome li:nth-child(n) {
  margin-top: 5px;
}
.menu-wellcome li:hover {

  box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19);
}
.message-error {
    margin-top: 7px;
    background-color: #FF6961;
    padding: 5px;
    color: #fff;
  }

.p-top {
    margin-top: 10px;
  }

/*css chatbotpage */
.header-box-mobile {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100px;
  border-bottom: 2px solid ${({ theme }) => theme.colors.gray[100]};
  position: fixed;
  top: 0;
  left: 0;
  background-color: #fff;
  z-index: 1000;
}

.header-box-mobile strong {
  margin-left: 10px;
}

.box-dialogue-mobile-old {
  width: 100%;
  height: 100vh; /* Alterado para 100vh para ocupar a altura total da viewport */
  position: fixed; /* ou absolute */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  overflow: hidden; /* Garantir que o conteúdo extra seja ocultado */
}

.box-dialogue-mobile {
  width: 100%;
   height: auto;
   position: fixed;
   border-radius: 5px;
   right: 0;
   bottom: 0;
   background-color: #fff;
   background-position: center;
   box-shadow: 0 9px 9px #999;
   z-index: -1;
}

.body-box-mobile {
  width: 100%;
  height: calc(100vh - 100px); /* Subtrair a altura do header */
  margin-top: 100px; /* Adicionar espaço para evitar sobreposição com o header */
  overflow-y: auto; /* Permitir rolagem vertical */
}

.scroll-messages-mobile {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100%;
  width: 100%;
  align-items: center;
  border-bottom: 2px solid ${({ theme }) => theme.colors.gray[100]};
  padding-top: 50px;
  padding-bottom: 50px;
}

.input-message-btn {
  position: relative;
  bottom: 0;
  width: 100%;
  display: flex;
  background-color: #fff;
  z-index: 1000;
}

.input-message {
  flex: 1;
  padding: 10px;
  border: none;
  border-top: 1px solid #ddd;
}

.send-button {
  background: none;
  border: none;
  padding: 10px;
  cursor: pointer;
}

.send-button .triangle {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #007bff;
  transform: rotate(90deg);
}
/*fim css chatbotpage */

/* Efeito maquina de escrever */
#elementEl::after {
  content: "|";
  margin-left: 5px;
  animation: blink 0.7s infinite;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

`;
